import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Firstsection from "../../components/marketingpages/Firstsection";
import Lastsection from "../../components/marketingpages/Lastsection";
import Secondsection from "../../components/marketingpages/Secondsection";
import Thirdsection from "../../components/marketingpages/Thirdsection";
import Chatbot from "../../components/chatBot";

export default function BizVerification() {
  return (
    <div>
      <Header />
      <div className="bg-[#f8f8f8] mt-[70px] mb-[64px] pt-[70px] flex flex-col gap-[150px] w-[85%] mx-auto">
        <Firstsection
          tag="Business Verification"
          mainHeader="Conduct KYB checks and onboard verified clients easily"
          paragraph="Access registries and reliable data sources in real-time to validate corporate entities and certify their legitimacy"
          getStartedLink="/"
          bookADemoLink="/"
          imgURL="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677677753/Group_bhcaki.svg"
          className="top-[-46px]"
          width="lg:w-[60%]"
        />
        <Secondsection
          header1="Multiple Business Verification"
          label1="Verify multiple businesses simultaneously and save valuable time."
          icon1="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678365/crisis-management_1_vad3dt.svg"
          header2="Automate Risk Assessment"
          label2="Optimize your workflows and increase efficiency by automating risk assessment."
          icon2="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678350/project_1_pnnlwj.png"
          header3="Multiple Company Integration"
          label3="Integrate multiple company profiles into one seamless system."
          icon3="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678339/distributed_1_qyxyf0.svg"
        />
        <Thirdsection
          mainHeader="Safeguard your company against fraud "
          paragraph="PowerdbyAI’s verification APIs and no-code tools help you detect counterfeits and forgeries on printed or digital documents. Our infrastructure provides for security against scams and other fraudulent attacks. "
          point1="Cross reference ID numbers with document data provided"
          point2="Identify fake IDs and documents"
          point3="Leverage automated review process"
          imgURL="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678960/Group_1_m24e4l.svg"
          className="right-[-60px]"
        />
        <Lastsection />
      </div>
      <Chatbot />
      <Footer />
    </div>
  );
}
