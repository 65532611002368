import { call, chat } from "../../assets";

export const actions = [
  {
    action: "chat",
    desc: "Instant message with our help assistant [AI]Chat",
    icon: chat,
  },
  {
    action: "call",
    desc: "Schedule a Call with a customer care Representative",
    icon: call,
  },
];

export const countries = [
  "Country",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const languages = [
  {
    label: "en",
    value: "english",
  },
  {
    label: "pd",
    value: "pidgin",
  },
  {
    label: "fr",
    value: "french",
  },
  {
    label: "ig",
    value: "igbo",
  },
  {
    label: "yr",
    value: "yoruba",
  },
  {
    label: "ha",
    value: "hausa",
  },
  {
    label: "sw",
    value: "swahili",
  },
  {
    label: "ar",
    value: "arabic",
  },
];

export const languageMap = {
  english: "en-US", // English
  pidgin: "en-NG", // Pidgin (fallback to Nigerian English, as there is no native support)
  french: "fr-FR", // French
  igbo: "ig-NG", // Igbo
  yoruba: "yo-NG", // Yoruba
  hausa: "ha", // Hausa
  swahili: "sw", // Swahili
  arabic: "ar-SA", // Arabic
};

export const timezones = ["Time zone", "UTC", "GMT", "PST", "WAT"];

export const translations = {
  english: {
    questions: [
      "What types of AI models does Powerdby[AI] offer for developers and businesses?",
      "How does Powerdby[AI] ensure the security of its AI model integrations and user data?",
      "Can I customise pre-trained models on Powerdby[AI] to fit specific business needs?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Instant message with our help assistant [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "Schedule a Call with a customer care Representative",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "For more info on our product offerings, engage with [AI] Chat, or speak to a customer care representative.",
      scheduleCall:
        "For specific requests, kindly fill out this form to schedule a call with a customer care representative.",
      customerCareRep: "[AI] Customer Care Representative",
      scheduleMessage:
        "Please schedule a call with our customer representative.",
      feedbackThankYou: "Thank you for your feedback.",
      callRequestSuccess:
        "Call Request Successful, check your email for the call details.",
    },
  },
  pidgin: {
    questions: [
      "Which type AI models Powerdby[AI] dey offer for developers and businesses?",
      "How Powerdby[AI] take dey ensure say their AI model integration and user data secure?",
      "I fit customize pre-trained models for Powerdby[AI] to fit my business needs?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Yarn wit our help assistant [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "Book appointment to talk wit customer care representative",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "To sabi more about our product, yarn wit [AI]Chat or talk to customer care representative.",
      scheduleCall:
        "If you get special request, abeg fill dis form make you book appointment with customer care representative.",
      customerCareRep: "[AI] Customer Care Representative",
      scheduleMessage:
        "Abeg book appointment wit our customer care representative.",
      feedbackThankYou: "Thank you for your feedback.",
      callRequestSuccess:
        "You don book appointment, check your email for the call details.",
    },
  },
  french: {
    questions: [
      "Quels types de modèles d'IA Powerdby[AI] propose-t-il aux développeurs et aux entreprises?",
      "Comment Powerdby[AI] garantit-il la sécurité de l'intégration de ses modèles d'IA et des données des utilisateurs?",
      "Puis-je personnaliser les modèles pré-entraînés sur Powerdby[AI] pour répondre aux besoins spécifiques de mon entreprise?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Message instantané avec notre assistant [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "Planifiez un appel avec un représentant du service client",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "Pour plus d'informations sur nos offres de produits, discutez avec [AI]Chat ou parlez à un représentant du service client.",
      scheduleCall:
        "Pour des demandes spécifiques, veuillez remplir ce formulaire pour planifier un appel avec un représentant du service client.",
      customerCareRep: "[AI] Représentant du service client",
      scheduleMessage:
        "Veuillez planifier un appel avec notre représentant du service client.",
      feedbackThankYou: "Merci pour vos commentaires.",
      callRequestSuccess:
        "Demande d'appel réussie, vérifiez votre email pour les détails de l'appel.",
    },
  },
  igbo: {
    questions: [
      "Kedu ụdị AI models Powerdby[AI] na-enye ndị mmemme na azụmahịa?",
      "Kedu ka Powerdby[AI] si hụ na nchekwa nke njikọ AI ya na data ndị ọrụ?",
      "Enwere m ike ịhazie ụdị ndị a kọwara na Powerdby[AI] ka ha kwekọọ na mkpa azụmahịa m?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Ozi ozugbo na onye enyemaka [AI]Chat anyị",
        icon: chat,
      },
      {
        action: "call",
        desc: "Tinye oge maka oku na onye nnọchiteanya ọrụ ndị ahịa",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "Maka ozi ndị ọzọ gbasara ihe anyị na-enye, soro [AI] Chat kparịta ụka, ma ọ bụ gwa onye nnọchiteanya ọrụ ndị ahịa okwu.",
      scheduleCall:
        "Maka arịrịọ pụrụ iche, biko juo fọm a ka ịtọlitee oge oku na onye nnọchiteanya ọrụ ndị ahịa.",
      customerCareRep: "[AI] Onye nnọchiteanya ọrụ ndị ahịa",
      scheduleMessage:
        "Biko dozie oge oku na onye nnọchiteanya ọrụ ndị ahịa anyị.",
      feedbackThankYou: "Daalụ maka nzaghachi gị.",
      callRequestSuccess: "Arịrịọ oku rụzuru, lelee email gị maka nkọwa oku.",
    },
  },
  yoruba: {
    questions: [
      "Àwọn irú àwòrán AI wo ni Powerdby[AI] ń pèsè fún àwọn onímọ̀ẹ̀rọ àti ilé iṣẹ́?",
      "Báwo ni Powerdby[AI] ṣe ń dáàbò bo àwòrán AI rẹ̀ àti ìbámu àti data àwọn oníṣe?",
      "Ṣe mo lè ṣe àtúnṣe àwọn àwòrán ti a ti kọ́ni sílè lórí Powerdby[AI] fún àwọn ohun tí ilé iṣẹ́ mi nílò?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Ìfiranṣẹ pẹ̀lú olùrànlọ́wọ́ [AI]Chat wa",
        icon: chat,
      },
      {
        action: "call",
        desc: "Ṣètò ìpe pẹ̀lú aṣojú tó ń gbójúfò àwọn oníbàárà",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "Láti mọ̀ sí i nípa àwọn ohun tí àwa nṣe, bá [AI] Chat ṣèrànwọ́ tàbí bá aṣojú oníbàárà sọrọ.",
      scheduleCall:
        "Fún àwọn ìbéèrè pàtàkì, ẹ̀ jọ̀ọ́ fọ́mù yìí láti ṣètò ìpe pẹ̀lú aṣojú oníbàárà.",
      customerCareRep: "[AI] Aṣojú Oníbàárà",
      scheduleMessage: "Ẹ̀ jọ̀ọ́ ṣètò ìpe pẹ̀lú aṣojú oníbàárà wa.",
      feedbackThankYou: "A dúpẹ́ fún ìfọ̀rọ̀wánilẹ́nu wá yín.",
      callRequestSuccess:
        "Ìbéèrè ìpe náà ti ṣàṣeyọrí, ẹ ṣàyẹ̀wò e-mail yín fún àwọn àlàyé ìpe.",
    },
  },
  hausa: {
    questions: [
      "Wadanne irin samfuran AI Powerdby[AI] ke bayarwa ga masu haɓaka da kamfanoni?",
      "Yaya Powerdby[AI] ke tabbatar da tsaro wajen haɗa samfuran AI da bayanan masu amfani?",
      "Zan iya daidaita samfuran da aka riga aka horar a Powerdby[AI] don dacewa da bukatun kasuwanci na?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Saƙon nan take tare da mataimakinmu [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "Tsara kiran waya tare da wakilin kula da abokan ciniki",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "Don ƙarin bayani game da samfuranmu, tattauna tare da [AI] Chat ko ku yi magana da wakilin kula da abokan ciniki.",
      scheduleCall:
        "Don buƙatun takamaimai, da fatan za a cika wannan fom don tsara kiran waya tare da wakilin kula da abokan ciniki.",
      customerCareRep: "[AI] Wakilin Kula da Abokan Ciniki",
      scheduleMessage: "Da fatan za a tsara kiran waya tare da wakilinmu.",
      feedbackThankYou: "Mun gode da ra'ayinku.",
      callRequestSuccess:
        "An samu nasarar ƙaddamar da buƙatar kira, duba imel ɗinku don cikakkun bayanan kiran.",
    },
  },
  swahili: {
    questions: [
      "Ni aina gani za mifano ya AI Powerdby[AI] inatoa kwa wasanidi na biashara?",
      "Powerdby[AI] inahakikishaje usalama wa miunganisho ya mifano ya AI na data za watumiaji?",
      "Je, ninaweza kubinafsisha mifano iliyo tayari kufunzwa kwenye Powerdby[AI] ili kukidhi mahitaji maalum ya biashara?",
    ],
    actions: [
      {
        action: "chat",
        desc: "Ujumbe wa papo hapo na msaidizi wetu [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "Panga simu na mwakilishi wa huduma kwa wateja",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "Kwa maelezo zaidi kuhusu bidhaa zetu, ongea na [AI] Chat, au zungumza na mwakilishi wa huduma kwa wateja.",
      scheduleCall:
        "Kwa maombi maalum, tafadhali jaza fomu hii kupanga simu na mwakilishi wa huduma kwa wateja.",
      customerCareRep: "[AI] Mwakilishi wa Huduma kwa Wateja",
      scheduleMessage:
        "Tafadhali panga simu na mwakilishi wetu wa huduma kwa wateja.",
      feedbackThankYou: "Asante kwa maoni yako.",
      callRequestSuccess:
        "Ombi la simu limefaulu, angalia barua pepe yako kwa maelezo ya simu.",
    },
  },
  arabic: {
    questions: [
      "ما هي أنواع نماذج الذكاء الاصطناعي التي تقدمها Powerdby[AI] للمطورين والشركات؟",
      "كيف تضمن Powerdby[AI] أمان تكامل نماذج الذكاء الاصطناعي وبيانات المستخدمين؟",
      "هل يمكنني تخصيص النماذج المدربة مسبقًا على Powerdby[AI] لتلبية احتياجات العمل الخاصة؟",
    ],
    actions: [
      {
        action: "chat",
        desc: "رسالة فورية مع مساعدنا [AI]Chat",
        icon: chat,
      },
      {
        action: "call",
        desc: "حدد موعدًا لإجراء مكالمة مع ممثل خدمة العملاء",
        icon: call,
      },
    ],
    infoTexts: {
      moreInfo:
        "لمزيد من المعلومات حول منتجاتنا، تواصل مع [AI] Chat، أو تحدث إلى ممثل خدمة العملاء.",
      scheduleCall:
        "للطلبات الخاصة، يُرجى ملء هذا النموذج لتحديد موعد مكالمة مع ممثل خدمة العملاء.",
      customerCareRep: "[AI] ممثل خدمة العملاء",
      scheduleMessage: "يرجى تحديد موعد مكالمة مع ممثل خدمة العملاء.",
      feedbackThankYou: "شكرًا على ملاحظاتك.",
      callRequestSuccess:
        "تم طلب المكالمة بنجاح، تحقق من بريدك الإلكتروني للحصول على تفاصيل المكالمة.",
    },
  },
};
