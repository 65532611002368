import { RequireAuth, ToDashboard } from "./components/auth";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Signup from "./pages/signup";
import Login from "./pages/login";
import AppHouse from "./pages/appHouse";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentModal from "./components/paymentModal";
import PasswordReset from "./pages/passwordReset";
import ConfirmReset from "./pages/confirmReset";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/Home";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import "@tremor/react/dist/esm/tremor.css";
import BizVerification from "./pages/marketingpages/BizVerification";
import IDV from "./pages/marketingpages/IDV";
import BiometricVerification from "./pages/marketingpages/BiometricVerification";
import PricingPage from "./pages/PricingPage";
import ComputerVision from "./pages/marketingpages/ComputerVision";

import useAuth from "./hooks/useAuth";
import Chatbot from "./components/chatBot";

function App() {
  const { authed } = useAuth();
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PaymentModal />
      <Routes>
        <Route
          exact
          path="/terms"
          element={
            <div className="contain-body" style={{ backgroundColor: "#fff" }}>
              <Header />
              <Terms />
              <Chatbot />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/privacy"
          element={
            <div className="contain-body" style={{ backgroundColor: "#fff" }}>
              <Header />
              <Privacy />
              <Chatbot />
              <Footer />
            </div>
          }
        />
        <Route
          path="/signup"
          element={!authed ? <Signup /> : <Navigate to="/app" />}
        />
        <Route
          path="/login"
          element={!authed ? <Login /> : <Navigate to="/app" />}
        />
        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/confirmreset" element={<ConfirmReset />} />
        <Route path="/businessverification" element={<BizVerification />} />
        <Route path="/identitydocumentverification" element={<IDV />} />
        <Route
          path="/biometricverification"
          element={<BiometricVerification />}
        />
        <Route path="/computervision" element={<ComputerVision />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route
          path="/app/*"
          element={
            <RequireAuth>
              <div className="contain-body">
                <Header />
                <AppHouse />
                <Footer />
              </div>
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <div className="contain-body">
              <Header />
              <ToDashboard>
                <Home />
              </ToDashboard>
              <Chatbot />
              <Footer />
            </div>
          }
        />
      </Routes>

      <style jsx="true">
        {`
          .contain-body {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }
        `}
      </style>
    </>
  );
}

export default App;
