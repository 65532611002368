import CryptoJS from "crypto-js";
const key = process.env.REACT_APP_SECRET_KEY;
const secretPass = CryptoJS.enc.Utf8.parse(`${key}` || "");

export const encryptData = (jsonObject) => {
  try {
    const plainText = JSON.stringify(jsonObject);

    const encryptResult = CryptoJS.AES.encrypt(
      plainText.toString(),
      secretPass,
      {
        iv: secretPass,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encryptResult.toString();
  } catch (error) {
    throw error;
  }
};

export const decryptData = (text) => {
  if (text) {
    try {
      const bytes = CryptoJS.AES.decrypt(text.toString(), secretPass, {
        iv: secretPass,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return data;
    } catch (error) {
      console.error("Decryption error:", error);
      return null; // or handle the error in an appropriate way
    }
  }
};
