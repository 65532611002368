import { Link } from "react-router-dom";

const socialLinks = [
  {
    href: "https://www.linkedin.com/company/2699628",
    icon: "fa-brands fa-linkedin",
    alt: "LinkedIn",
  },
  {
    href: "https://www.instagram.com/analyticsintelligence/",
    icon: "fa-brands fa-square-instagram",
    alt: "Instagram",
  },
  {
    href: "https://twitter.com/_AnalyticsIntel",
    icon: "fa-brands fa-twitter",
    alt: "Twitter",
  },
  {
    href: "https://www.facebook.com/Analyticsintel/",
    icon: "fa-brands fa-facebook",
    alt: "Facebook",
  },
];

const footerLinks = [
  {
    title: "Learn",
    links: [
      {
        text: "[AI] Academy",
        href: "https://analyticsintelligence.com/academy",
      },
      { text: "Documentation", href: "https://powerdbyai.readme.io/" },
      { text: "Community", href: "https://bit.ly/3CL7kJU" },
    ],
  },
  {
    title: "Use Case",
    links: [
      { text: "Financial services" },
      { text: "Mobility and Logistics" },
      { text: "E-commerce" },
      { text: "Telco and Utilities" },
      { text: "Crypto" },
      { text: "Fraud Detection" },
    ],
  },
  {
    title: "Documentation",
    links: [
      { text: "Product Documentation", href: "https://powerdbyai.readme.io/" },
      { text: "Developer Community", href: "https://bit.ly/3CL7kJU" },
    ],
  },
  {
    title: "Company",
    links: [
      {
        text: "About us",
        href: "https://analyticsintelligence.com/company",
        target: true,
      },
      { text: "Pricing", href: "/pricing", target: false },
      {
        text: "Blog",
        href: "https://africa.analyticsintelligence.com/blog",
        target: true,
      },
      { text: "Contact sales" },
      { text: "Privacy policy", href: "/privacy", target: false },
      { text: "Terms and conditions", href: "/terms", target: false },
      {
        text: "Careers",
        href: "https://analyticsintelligence.com/careers",
        target: true,
      },
    ],
  },
];

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container container">
        <div className="contain-box">
          <Link to="/" reloadDocument>
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1662379016/logo.25f888ac_le80rk.svg"
              alt="logo"
              className="logo"
              width="150"
              style={{ marginLeft: "-20px" }}
            />
          </Link>
          <div className="mb-3">
            <a
              href="mailto:info@analyticsintelligence.com"
              target="_blank"
              rel="noreferrer"
            >
              info@analyticsintelligence.com
            </a>
          </div>
          <div className="icons mt-3">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                className="is-inline-block mr-2"
              >
                <i className={link.icon}></i>
              </a>
            ))}
          </div>
        </div>
        {footerLinks.map((section, index) => (
          <div key={index} className="contain-box">
            <ul>
              <li className="mb-2">
                <a className="has-text-weight-bold" href="#">
                  {section.title}
                </a>
              </li>
              {section.links.map((link, index) => (
                <li key={index} className="mb-2">
                  {link.href ? (
                    <a
                      href={link.href}
                      target={`${link.target ? "_blank" : ""}`}
                      rel="noreferrer"
                    >
                      {link.text}
                    </a>
                  ) : (
                    <span>{link.text}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .footer {
          background-color: #fff;
          padding-top: 3rem;
          padding-bottom: 3rem;
          color: #00093a;
          margin-top: auto;
          line-height: 1.6;
        }
        .footer .footer-container {
          align-items: flex-start;
          flex-wrap: wrap;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-gap: 4.7rem;
        }

        .contain-box {
          padding: 1rem;
        }
      `}</style>
    </div>
  );
}
