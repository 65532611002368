import ReactDOM from "react-dom";
import React, { useState } from "react";
import useAuth from "./../hooks/useAuth";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { TOPUP } from "./../utils/apis";
import fetcher from "../utils/fetcher";

const InternalTransactions = ({ setDisplay, token }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      toast.dismiss();
      setLoading(true);
      toast.loading("Please wait...");

      const body = await fetcher(TOPUP, {
        method: "POST",
        body: JSON.stringify({ ...data }),
        headers: {
          "X-API-KEY": `${token}`,
          "Content-Type": "application/json",
        },
      });

      if (body.failure) {
        throw body.message;
      }
      setLoading(false);
      toast.dismiss();
      window.location.href = body.data.link;
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div id="payment">
      <div className="transaction-card">
        <div>
          <h3>Top up Wallet</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label">Amount</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className={`input ${errors.amount ? "is-danger" : ""}`}
                  type="number"
                  placeholder="Enter amount"
                  {...register("amount", {
                    required: true,
                    min: 50,
                  })}
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-naira-sign"></i>
                </span>
                {errors.amount && (
                  <span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle"></i>
                  </span>
                )}
              </div>
              {errors.amount && (
                <p className="help is-danger">
                  Amount cannot be less than{" "}
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(50)}
                </p>
              )}
            </div>
            <div className="field">
              <p className="control">
                <button
                  type="submit"
                  className={`spread-button has-text-weight-bold full mt-5 button ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Top up
                </button>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button
                  className="button is-danger is-inverted full has-text-weight-bold"
                  onClick={setDisplay}
                >
                  Cancel
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <style jsx="true">{`
        #payment {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          overflow: hidden;
          z-index: 50000;
          padding: 1rem;
        }
        #payment h3 {
          color: var(--main-hue);
          font-weight: 800;
          margin-bottom: 1.5rem;
          text-align: center;
        }
        #payment label {
          color: var(--main-hue);
        }
        #payment .transaction-card {
          background-color: #fff;
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          border-radius: 10px;
          padding: 2rem;
          position: relative;
          margin-top: 15vh;
        }
      `}</style>
    </div>
  );
};
export default function PaymentModal() {
  const { displayPaymentModal, setDisplayPaymentModal, token } = useAuth();
  if (displayPaymentModal) {
    return ReactDOM.createPortal(
      <InternalTransactions
        setDisplay={() => setDisplayPaymentModal(false)}
        token={token}
      />,
      document.body
    );
  }
  return null;
}
