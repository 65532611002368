import { Routes, Route } from "react-router-dom";
import AppBody from "../components/appBody";
import Idntity from "../components/idntity";
import Settings from "../components/settings";
import Wallet from "../components/wallet";

export default function AppHouse() {
  return (
    <main className="app-house">
      <div style={{ marginTop: "85px" }}></div>
      <Routes>
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/settings/*" element={<Settings />} />
        <Route exact path="/idntity/*" element={<Idntity />} />
        <Route exact path="/" element={<AppBody />} />
      </Routes>
    </main>
  );
}
