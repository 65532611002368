import { useState } from "react";
import useAuth from "./../hooks/useAuth";

export default function AppBody() {
  const { user } = useAuth();
  const [stagingCopies, setStagingCopies] = useState(false);
  const [productionCopies, setProductionCopies] = useState(false);

  return (
    <div className="container  p-5">
      <div className="blue-box mb-5">
        <h1 className="mb-5 has-text-weight-bold">Welcome to Powerdby[AI]</h1>
        <p className="pretrained mb-5">
          Powered by [AI] is a consolidated machine learning API infrastructure,
          that lets you build smart applications with intelligent capabilities
        </p>
        <p className="pretrained">
          Our pre-trained machine learning API includes models for - identity
          verification, object detection, natural language processing,
          predictive modelling and customer loyalty rewards with analytics, all
          with one account.
        </p>
      </div>
      <div className="key">
        <div className="account-info">
          <h4>Account information</h4>
        </div>
        <div className="keys">
          <div className="field">
            <label className="label">
              <small className="is-uppercase has-text-grey is-size-7">
                Staging API Key
              </small>
            </label>
            <div className={`control`}>
              <input
                className={`input`}
                type="text"
                placeholder="API token"
                readOnly
                defaultValue={user.testKey}
              />
            </div>
          </div>
          <button
            className="keys-icon"
            onClick={() => {
              navigator.clipboard.writeText(user.testKey).then(() => {
                setStagingCopies(true);
                setTimeout(() => {
                  setStagingCopies(false);
                }, 5000);
              });
            }}
          >
            {stagingCopies ? (
              <>
                <div className="icon is-large">
                  <i className="fa-solid fa-check"></i>
                </div>
                <div>Copied</div>
              </>
            ) : (
              <>
                <div>
                  <span className="icon is-large">
                    <i className="fa-solid fa-copy"></i>
                  </span>
                </div>
                <div>Copy</div>
              </>
            )}
          </button>
        </div>
        <div className="keys" style={{ marginTop: "20px" }}>
          <div className="field">
            <label className="label">
              <small className="is-uppercase has-text-grey is-size-7">
                Production API Key
              </small>
            </label>
            <div className={`control`}>
              <input
                className={`input`}
                type="text"
                placeholder="API token"
                readOnly
                defaultValue={user.key}
              />
            </div>
          </div>
          <button
            className="keys-icon"
            onClick={() => {
              navigator.clipboard.writeText(user.key).then(() => {
                setProductionCopies(true);
                setTimeout(() => {
                  setProductionCopies(false);
                }, 5000);
              });
            }}
          >
            {productionCopies ? (
              <>
                <div className="icon is-large">
                  <i className="fa-solid fa-check"></i>
                </div>
                <div>Copied</div>
              </>
            ) : (
              <>
                <div>
                  <span className="icon is-large">
                    <i className="fa-solid fa-copy"></i>
                  </span>
                </div>
                <div>Copy</div>
              </>
            )}
          </button>
        </div>

      </div>
      <h2 className="mt-6 mb-6 has-text-weight-bold">
        Start building with our APIs
      </h2>
      <div className="product">
        <div className="lg:w-[50%]">
          <h5 className="md:text-[33px] text-[25px] font-[700]">
            Everything you need to onboard customers and verify customer
            identity
          </h5>
          <p className="mb-3">
            PowerdbyAI helps companies and digital business verify, onboard and
            manage user identity across Africa.
          </p>
          <a href="https://powerdbyai.readme.io/" target="_blank" rel="noreferrer">
            <button
              className="spread-button"
              style={{
                color: "#0066ff",
                backgroundColor: "#fff",
              }}
            >
              View documentation
            </button>
          </a>
        </div>
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669057132/Group_11743_1_icphem.png"
          alt="guards"
          className="guards"
        />
      </div>
      <style jsx="true">{`
        .product {
          padding: 2rem;
          border-radius: 20px;
          color: #fff;
          background-image: linear-gradient(to right, #0066ff, #0027b2);
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 100px;
          flex-wrap: wrap;
        }

        .product .guards {
          position: absolute;
          right: 40px;
          flex-basis: 300px;
        }
        @media only screen and (max-width: 960px) {
          .product .guards {
            display: none;
          }
        }
        .product h5 {
          font-weight: 900;
          margin-bottom: 1.5rem;
          text-transform: capitalize;
        }
        .product p {
          font-size: 1rem;
          color: rgba(255, 255, 255, 0.7);
          max-width: 450px;
          line-height: 1.7;
        }
        .product a {
          color: var(--main-hue);
          display: inline-block;
          margin-top: 1rem;
        }
        .blue-box {
          background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1669056467/Background-02-01_1_hkn6wp.png");
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
          padding: 10vh 2rem;
          border-radius: 20px;
        }

        .blue-box .pretrained {
          max-width: 500px;
          color: rgba(255, 255, 255, 0.7);
        }
        input {
          cursor: pointer;
          max-width: 100%;
          border-width: 2px !important;
        }
        .key {
          margin-top: 2rem;
          margin-bottom: 2rem;
          background-color: #dfe9f9;
          border-radius: 20px;
          padding-bottom: 1.5rem;
        }
        button.keys-icon {
          height: 100%;
          display: flex;
          cursor: pointer;
          background-color: #fff;
          border: 0px;
          outline: 0px;
          color: #051572;
          font-size: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          align-items: center;

          margin: 0px;
          margin-right: 1rem;
        }
        .key .keys {
          background-color: #fff;
          border-radius: 20px;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          display: flex;
          align-items: center;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        .key .keys .label {
          margin-bottom: 0px !important;
        }
        .keys .input {
          border: 0px solid #000 !important;
          padding-left: 0px;
        }
        .keys .field {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: 0px !important;
          border-right: 1px solid #eee;
        }
        .key .account-info {
          padding: 1rem 0rem 1rem 1.5rem;
        }
        .key .account-info h4 {
          font-weight: 800;
          font-size: 120%;
        }
      `}</style>
    </div>
  );
}
