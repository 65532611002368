import React from 'react'

export default function Secondsection({header1, header2, header3, label1, label2, label3, icon1, icon2, icon3}) {
  return (
    <div className='flex md:flex-row flex-col items-center  md:gap-[32px] gap-[80px]'>
        <div className='h-[302px] flex flex-col gap-[21px] md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[25px] pt-[100px] pb-[65px] bg-[#fff]'>
            <div className='flex justify-center items-center h-[113px] w-[113px] rounded-[50%] bg-[#0165FE] absolute top-[-42px] left-[22px]'>
              <img src={icon1} alt="icon" />
            </div>
            <h5 className='md:text-[20px] text-[18px] font-[700] text-[#14142BB2]'>{header1}</h5>
            <p className='md:text-[16px] text-[14px]'>{label1}</p>
        </div>
        <div className='h-[302px] flex flex-col gap-[21px] md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[25px] pt-[100px] pb-[65px] bg-[#fff]'>
            <div className='flex justify-center items-center h-[113px] w-[113px] rounded-[50%] bg-[#0165FE] absolute top-[-42px] left-[22px]'>
              <img src={icon2} alt="icon" />
            </div>
            <h5 className='md:text-[20px] text-[18px] font-[700] text-[#14142BB2]'>{header2}</h5>
            <p className='md:text-[16px] text-[14px]'>{label2}</p>
        </div>
        <div className='h-[302px] flex flex-col gap-[21px] md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[25px] pt-[100px] pb-[65px] bg-[#fff]'>
            <div className='flex justify-center items-center h-[113px] w-[113px] rounded-[50%] bg-[#0165FE] absolute top-[-42px] left-[22px]'>
              <img src={icon3} alt="icon" />
            </div>
            <h5 className='md:text-[20px] text-[18px] font-[700] text-[#14142BB2]'>{header3}</h5>
            <p className='md:text-[16px] text-[14px]'>{label3}</p>
        </div>
        <style jsx='true'>
          {`
            .shadow-box{
              box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
            }
          `}
        </style>
    </div>
  )
}
