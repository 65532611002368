import { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { toast } from "react-toastify";

export default function VotersVerification() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  console.log(payload);
  return (
    <div>
      <div className="contain">
        <div className="contain-second prod">
          <form
            method="post"
            encType="multipart/form-data"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                setPayload(null);
                setLoading(true);

                const formData = new FormData(e.target);

                const data = Object.fromEntries(formData);

                let res = await fetch(
                  `/api/idntity/identities/vin/${data.bvn}`,
                  {
                    method: "POST",
                    headers: {
                      "x-api-key": user.testKey,
                      Accept: `application/json, text/plain, */*`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                  }
                );
                // if (!res.ok) {
                //   throw new Error("Something went wrong");
                // }
                try {
                  const responseData = await res.json();
                
                  if (responseData.status === "success") {
                    setPayload(responseData.data);
                    return toast.success("Request successful");
                  } else {
                    // Handle server-side errors (e.g., validation errors, request processing errors)
                    const errorMessage = responseData.message;
                    throw new Error(errorMessage);
                  }
                } catch (error) {
                  // Handle JSON parsing errors or other unexpected errors
                  throw new Error(error.message);
                }
              } catch (err) {
                toast.dismiss();
                toast.error(err.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <div className="field">
              <label className="label" htmlFor="firstName">
                First name
              </label>
              <input
                className="input is-normal"
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Enter first name"
                required
              ></input>
            </div>
            <div className="field">
              <label className="label" htmlFor="lastName">
                Last name
              </label>
              <input
                className="input is-normal"
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Enter last name"
                required
              ></input>
            </div>
            <div className="field">
              <label className="label" htmlFor="bvn">
                Voter's card number
              </label>
              <input
                className="input is-normal"
                type="tel"
                name="bvn"
                id="bvn"
                placeholder="Enter Voter's card number"
                required
                maxLength={17}
                minLength={17}
              ></input>
            </div>

            <div className="field">
              <label className="label" htmlFor="dob">
                Date of birth
              </label>
              <input
                className="input is-normal"
                type="date"
                name="dob"
                id="dob"
                placeholder="Enter date of birth"
                required
              ></input>
            </div>

            <div className="field">
              <p className="control">
                <button
                  className={`spread-button full mt-5 button ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Lookup
                </button>
              </p>
            </div>
          </form>
        </div>
        <div className="contain-first prod" style={{ padding: "0px" }}>
          {!loading && payload?.firstName && (
            <div className="results-container  p-5">
              <label className="label mb-2">Personal Details</label>
              <div className="results mt-5 pl-1">
                <p className="has-text-grey-light">First name</p>
                <p>{payload.firstName}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Last name</p>
                <p>{payload.lastName}</p>
              </div>

              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Gender</p>
                <p className="is-capitalized">{payload.gender}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Occupation</p>
                <p className="is-capitalized">{payload.occupation}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">State</p>
                <p className="is-capitalized">{payload.state}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Lga</p>
                <p className="is-capitalized">{payload.lga}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Polling Unit</p>
                <p className="is-capitalized">{payload.pollingUnit}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Polling Unit Code</p>
                <p className="is-capitalized">{payload.pollingUnitCode}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Time of Registration</p>
               <p>{payload.timeOfRegistration}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">VIN</p>
                <p>{payload.vin}</p>
              </div>
            </div>
          )}
        </div>
        <style jsx="true">
          {`
            .results {
              display: flex;
              align-items: center;
            }
            .results > p:first-of-type {
              flex-basis: 150px;
              flex-shrink: 0;
            }

            .prod {
              padding: 1rem 2rem;
              border: 1px solid #eee;
              border-radius: 10px;
            }
            .prod img {
              width: 100%;
              height: 100%;
            }
            .contain {
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
              grid-auto-rows: minmax(300px, auto);
              grid-gap: 1rem;
              justify-content: space-between;
              margin-bottom: 1.5rem;
            }
          `}
        </style>
      </div>
    </div>
  );
}
