import React from 'react'
import { Link } from 'react-router-dom'

export default function Lastsection() {
  return (
    <div className='flex md:flex-row flex-col items-center  md:gap-[42px] gap-[80px]'>
    <div className=' md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[36px] py-[42px] bg-[#fff] flex flex-col gap-[34px] items-center text-center'>
        <p>One pricing plan for all your business and product needs</p>
        <Link className='text-[14px] sm:text-[16px] w-[70%] text-center bg-[#0165FE] py-[12px] rounded-[12px] text-white hover:text-white hover:opacity-70' to='/pricing' reloadDocument> See pricing</Link>
    </div>
    <div className='md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[12px] py-[42px] bg-[#fff] flex flex-col gap-[34px] items-center text-center'>
        <p>Up-to-date guides to get your developers working with our APIs</p>
        <a href="https://powerdbyai.readme.io/" target="_blank" rel="noreferrer" className='text-[14px] sm:text-[16px] w-[70%] text-center bg-[#0165FE] py-[12px] rounded-[12px] text-white hover:text-white hover:opacity-70 ' to='/docs'>View Documentation</a>
    </div>
    <div className=' md:w-[33%] sm:w-[60%] w-[80%] relative shadow-box rounded-[20px] px-[32px] py-[42px] bg-[#fff] flex flex-col gap-[34px] items-center text-center'>
        <p>Transform the way you verify businesses today</p>
        <Link className='text-[14px] sm:text-[16px] w-[70%] text-center bg-[#0165FE] py-[12px] rounded-[12px] text-white hover:text-white hover:opacity-70' to='/app/playground' reloadDocument>Book a Demo</Link>
    </div>
    <style jsx='true'>
          {`
            .shadow-box{
              box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
            }
          `}
        </style>
</div>
  )
}
