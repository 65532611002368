import React from "react";

export const LikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3333 6.66669C15.3333 6.31307 15.1928 5.97393 14.9428 5.72388C14.6927 5.47383 14.3536 5.33335 14 5.33335H9.78663L10.4266 2.28669C10.44 2.22002 10.4466 2.14669 10.4466 2.07335C10.4466 1.80002 10.3333 1.54669 10.1533 1.36669L9.44663 0.666687L5.05996 5.05335C4.81329 5.30002 4.66663 5.63335 4.66663 6.00002V12.6667C4.66663 13.0203 4.8071 13.3594 5.05715 13.6095C5.3072 13.8595 5.64634 14 5.99996 14H12C12.5533 14 13.0266 13.6667 13.2266 13.1867L15.24 8.48669C15.3 8.33335 15.3333 8.17335 15.3333 8.00002V6.66669ZM0.666626 14H3.33329V6.00002H0.666626V14Z"
        fill="#0066ff"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33329 6.00002V14H0.666626V6.00002H3.33329ZM5.99996 14C5.64634 14 5.3072 13.8595 5.05715 13.6095C4.8071 13.3594 4.66663 13.0203 4.66663 12.6667V6.00002C4.66663 5.63335 4.81329 5.30002 5.05996 5.06002L9.44663 0.666687L10.1533 1.37335C10.3333 1.55335 10.4466 1.80002 10.4466 2.07335L10.4266 2.28669L9.79329 5.33335H14C14.3536 5.33335 14.6927 5.47383 14.9428 5.72388C15.1928 5.97393 15.3333 6.31307 15.3333 6.66669V8.00002C15.3333 8.17335 15.3 8.33335 15.24 8.48669L13.2266 13.1867C13.0266 13.6667 12.5533 14 12 14H5.99996ZM5.99996 12.6667H12.02L14 8.00002V6.66669H8.13996L8.89329 3.12002L5.99996 6.02002V12.6667Z"
        fill="#0066ff"
      />
    </svg>
  );
};
