import useAuth from "./../hooks/useAuth";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header() {
  const { user, logout, setDisplayPaymentModal, authed } = useAuth();
  const [isActive, setIsActive] = useState(false);
  const [idntityDropdown, setIdntityDropdown] = useState(false);
  const [perceptionXDropdown, setPerceptionXDropdown] = useState(false);

  const handleIdropdown = () => {
    idntityDropdown ? setIdntityDropdown(false) : setIdntityDropdown(true);
    setPerceptionXDropdown(false);
  };
  const handlePdropdown = () => {
    perceptionXDropdown
      ? setPerceptionXDropdown(false)
      : setPerceptionXDropdown(true);
    setIdntityDropdown(false);
  };
  return (
    <nav className="navbar  is-white is-fixed-top">
      <div className="container ">
        <div className="navbar-brand">
          <NavLink className="navbar-item hue" to="/">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1662379016/logo.25f888ac_le80rk.svg"
              alt="Powerdby[AI]"
              style={{
                objectFit: "cover",
                minWidth: "150px",
                minHeight: "55px",
              }}
            />
          </NavLink>
          <div
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            onClick={() => setIsActive((s) => !s)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-start"></div>
          {authed ? (
            <div className="navbar-end">
              <NavLink className="navbar-item hue" to="/app" reloadDocument>
                Home
              </NavLink>
              <div className="navbar-item has-dropdown is-hoverable hue">
                <a className="navbar-link">Wallet</a>
                <div className="navbar-dropdown">
                  <a
                    className="navbar-item"
                    onClick={() => setDisplayPaymentModal(true)}
                  >
                    Add funds to wallet
                  </a>
                  <NavLink
                    className="navbar-item"
                    to="/app/wallet"
                    reloadDocument
                  >
                    View transactions history
                  </NavLink>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable hue">
                <a className="navbar-link">Products</a>
                <div className="navbar-dropdown">
                  <NavLink
                    to="/app/idntity"
                    className="navbar-item"
                    reloadDocument
                  >
                    Idntity
                  </NavLink>
                </div>
              </div>
              <div className="navbar-item">
                <NavLink to="/pricing" reloadDocument>
                  Pricing
                </NavLink>
              </div>
              <div className="navbar-item has-dropdown is-hoverable hue">
                <a className="navbar-link">
                  <div className="iconic">
                    <span
                      className="initials"
                      style={{ textTransform: "uppercase" }}
                    >
                      {user?.name?.split(" ")[0][0]}
                      {user?.name?.split(" ")[0][1]}
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {user?.name?.split(" ")[0]}
                    </span>
                  </div>
                </a>
                <div className="navbar-dropdown">
                  <NavLink
                    className="navbar-item"
                    to="/app/settings"
                    reloadDocument
                  >
                    Settings
                  </NavLink>
                  <a
                    className="navbar-item"
                    onClick={() => logout().catch(console.log)}
                  >
                    Log out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable hue">
                <a className="navbar-link">Products</a>
                <div className="navbar-dropdown">
                  <NavLink
                    to="/businessverification"
                    className="navbar-item"
                    reloadDocument
                  >
                    Business Verification
                  </NavLink>
                  <NavLink
                    to="/identitydocumentverification"
                    className="navbar-item"
                    reloadDocument
                  >
                    Document Verification
                  </NavLink>
                  <NavLink
                    to="/biometricverification"
                    className="navbar-item"
                    reloadDocument
                  >
                    Biometric Verification
                  </NavLink>
                  <NavLink
                    to="/computervision"
                    className="navbar-item"
                    reloadDocument
                  >
                    Computer Vision
                  </NavLink>
                </div>
              </div>
              <div className="navbar-item">
                <NavLink to="/pricing" reloadDocument>
                  Pricing
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink to="/login" reloadDocument>
                  Login
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink
                  className="spread-button"
                  to="/signup"
                  reloadDocument
                  style={{
                    color: "#fff",
                    backgroundColor: "#0066ff",
                  }}
                >
                  Create account
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx="true">
        {`
          .navbar-brand {
            align-items: center;
          }

          .iconic {
            display: flex;
            align-items: center;
          }
          .initials {
            background-color: rgb(254, 254, 254);
            color: var(--main-hue);
            margin-right: 0.5rem;
            width: 40px;
            text-align: center;
            border-radius: 50%;
            line-height: 40px;
            font-weight: 800;
          }
        `}
      </style>
    </nav>
  );
}
