import { useState } from "react";
import { languages } from "./constants";
import { GlobeIcon } from "../../icons";

const LangusgeSwitcher = ({ lang, setLang }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (language) => {
    setLang(language);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const language = languages?.filter((l) => l.value === lang)[0];

  return (
    <div className="relative">
      <div
        className={`text-[#0066ff] flex cursor-pointer justify-center items-center`}
        onClick={toggleDropdown}
      >
        <GlobeIcon />
        <div className="mx-1 uppercase text-[13px]">{language?.label}</div>
        <svg
          className={`transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
        >
          <path d="M0 0L5 5L10 0H0Z" fill="#0066ff" />
        </svg>
      </div>
      {isOpen && (
        <div className="z-50 absolute top-10 list-none bg-[#F9F9F9] shadow w-[143px] h-[191px] rounded-[6px] border border-[rgba(128,80,57,0.20)]  px-1 py-[10px]">
          <ul className="overflow-y-auto h-full space-y-2" role="none">
            <div className="text-[10px] font-semibold leading-[22px] px-1 text-[#00093A]">
              Select Language
            </div>
            {languages?.map((lang) => (
              <li key={lang?.value}>
                <span
                  className={`transition-all capitalize duration-800 ease-in-out flex items-center px-[10px] h-[22px]   cursor-pointer  hover:bg-primary leading-[24px]  text-[12px] rounded-[4px] ${
                    language?.value === lang?.value
                      ? "bg-primary shadow text-white"
                      : "text-[#00093A] hover:text-white"
                  }`}
                  onClick={() => {
                    handleLanguageChange(lang?.value);
                  }}
                >
                  {lang?.value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default LangusgeSwitcher;
