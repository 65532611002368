import { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { toast } from "react-toastify";
import BoundingBox from "./BoundingBox";

export default function ObjectDetection() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  return (
    <div>
      <div className="contain">
        <div className="contain-second prod">
          <form
            method="post"
            className="max-w-[500px] mx-auto"
            encType="multipart/form-data"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                setPayload([]);
                setLoading(true);

                const formData = new FormData(e.target);

                let res = await fetch(`/api/ml/cv/food_detection`, {
                  method: "POST",
                  headers: {
                    "x-api-key": user.testKey,
                    Accept: `application/json, text/plain, */*`,
                  },
                  body: formData,
                });
                // if (!res.ok) {
                //   throw new Error("Something went wrong");
                // }
                try {
                  const responseData = await res.json();
                  setPayload(responseData);

                  if (res.ok) {
                    if (responseData.length > 0) return toast.success("Request successful")
                    if (responseData.length === 0) return toast.success("No object detected in the Image");
                  } else {
                    // Handle server-side errors (e.g., validation errors, request processing errors)
                    const errorMessage = responseData.message;
                    throw new Error(errorMessage);
                  }
                } catch (error) {
                  // Handle JSON parsing errors or other unexpected errors
                  throw new Error(error.message);
                }
              } catch (err) {
                toast.dismiss();
                toast.error(err.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <div className="field">
              <div className="photo-holder">
                <input
                  type="file"
                  name="upload_file"
                  id="upload_file"
                  placeholder="Select image"
                  required
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      setSelectedImage(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                ></input>
                <div className="photo-holder-main">
                  {selectedImage ? (
                    <img src={selectedImage} />
                  ) : (
                    <p>Drag or <span className="underline text-[#0047FF] cursor-pointer">Upload</span> an Image here</p>
                  )}
                </div>
              </div>
            </div>

            <div className="field">
              <p className="control">
                <button
                  className={`spread-button full mt-5 button ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Lookup
                </button>
              </p>
            </div>
          </form>
        </div>
        <div className="contain-first prod">
          <label className="label" htmlFor="bvn">
              Results
          </label>
          {payload.length > 0 && <div className="mx-auto mb-[30px]">
            <BoundingBox coordinates={payload} selectedImage={selectedImage}/>
          </div>}
          <div>
            <label className="label" htmlFor="bvn">
              Object(s)
            </label>
            <table>
              <thead>
                <tr>
                  <th className="hidden sm:table-cell">S/N</th>
                  <th>Object</th>
                  <th className="hidden sm:table-cell">X Coordinates</th>
                  <th className="hidden sm:table-cell">Y Coordinates</th>
                  <th>Confidence Score</th>
                </tr>
              </thead>
              <tbody>
                {payload.length > 0 && payload.map((p, index) => (
                  <tr key={p.object + index}>
                    <td className="hidden sm:table-cell">{index + 1}</td>
                    <td>{p.object}</td>
                    <td className="hidden sm:table-cell">
                      &#123;{p.bbox.xmin},{p.bbox.xmax}&#125;
                    </td>
                    <td className="hidden sm:table-cell">
                      &#123;{p.bbox.ymin},{p.bbox.ymax}&#125;
                    </td>
                    <td>{Number(p.confidence_score * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {payload.length > 0 && <div className="flex sm:flex-row flex-col justify-end gap-[26px] mt-[30px]">
            <button className="bg-[#FB0000] py-[12px] sm:w-[140px] w-full rounded-[12px] text-white" onClick={() => setPayload([])}>Clear Results</button>
            {/* <button className="bg-[#344D8C] py-[12px] sm:w-[140px] w-full rounded-[12px] text-white">Export</button> */}
          </div>}
        </div>
        <style jsx="true">
          {`
            table {
              width: 100%;
            }
            thead th {
              background-color: rgba(0, 0, 0, 0.05);
            }
            table th {
              padding: 0.5rem;
            }
            table td {
              padding: 0.5rem;
              text-transform: capitalize;
            }
            table tbody tr:nth-of-type(even) {
              background-color: rgba(0, 0, 0, 0.05);
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
            .photo-holder-main p {
              text-align: center;
            }
            .photo-holder-main img {
              object-fit: cover;
              border-radius: 10px;
              height: 100%;
              width: 100%;
            }
            .photo-holder-main {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .photo-holder {
              height: 300px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 3px dashed;
              border-color: #ccc;
              border-radius: 10px;
            }
            .photo-holder input {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 2;
              padding: 1rem;

              opacity: 0;
            }
            .results {
              display: flex;
              align-items: center;
            }
            .results > p:first-of-type {
              flex-basis: 150px;
              flex-shrink: 0;
            }

            .prod {
              padding: 1rem 2rem;
              border: 1px solid #eee;
              border-radius: 10px;
            }
            .contain {
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-bottom: 1.5rem;
            }
            @media screen and (max-width: 600px) {
              .photo-holder{
                height:200px;
              }
            }
          `}
        </style>
      </div>
    </div>
  );
}
