import { ANALYTICS_KEY } from "./constants";

export async function altFetcher(url, options) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      api_key: ANALYTICS_KEY,
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.message);
  }

  if (response.status === 204) {
    return null;
  } else {
    return response.json();
  }
}

export default async function fetcher(url = "", content = {}) {
  try {
    const res = await fetch(url, content);
    const data = await res.json();

    if (!res.ok || !data.success) {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    return { failure: true, message: error.message };
  }
}
