import { Link } from "react-router-dom";
export default function Home() {
  return (
    <main className="home">
      <div className="container  p-3">
        <div className="blue-box mb-5">
          <h1 className="mb-5 has-text-weight-bold">
            Making artificial intelligence accessible to anyone, anywhere.
          </h1>
          <p className="pretrained mb-5">
            PoweredbyAI has developed solutions that help companies harness and
            structure the value of their data to find insight and enable
            intelligence. We make intelligence available on-demand and delivered
            as a service.
          </p>
          <Link to="/signup" className="mb-4">
            <button
              className="spread-button"
              style={{
                color: "#fff",
                backgroundColor: "#0066ff",
              }}
            >
              Sign up now
            </button>
          </Link>
        </div>
        <div className="second mb-5">
          <div className="second-first mb-5">
            <h3 className="mt-4 mb-5 has-text-weight-bold">
              Why Powerdby [AI]?
            </h3>
            <p className="mb-3">
              Powerdby [AI] is a platform for developers and AI specialists to
              create, test, and deploy machine learning models in simple API
              integrations. It enables the deployment of machine learning models
              making use of the API library provided by API Platform.
            </p>
            <p className="mb-3">
              With no coding required, Powerdby [AI] allows you to integrate
              with your existing systems without incurring downtime or
              introducing any further complexity into your internal operations.
            </p>
          </div>
          <div className="second-second mb-5">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669112461/Frame_11889_kfwsoq.png"
              alt="frame"
            />
          </div>
        </div>
        <div className="second mb-5" style={{ backgroundColor: "transparent" }}>
          <div className="second-second mb-5" style={{ flexGrow: 1 }}>
            <h3 className="mt-4 mb-5 has-text-weight-bold has-text-centered">
              What machine learning models can I get from Powerdby[AI]?
            </h3>
            <div className="image-grid">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669115709/Frame_11882_fb5cyk.png"
                alt="frame"
              />
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669115709/Frame_11883_xekjsj.png"
                alt="frame"
              />
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669115709/Frame_11886_kowqsx.png"
                alt="frame"
              />
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669115709/Frame_11884_jk4gd4.png"
                alt="frame"
              />
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669115709/Frame_11885_omqw9l.png"
                alt="frame"
              />
            </div>
          </div>
        </div>
        <div className="second mb-5 grad-box">
          <div className="second-first mb-5">
            <h3 className="mt-4 mb-5 has-text-weight-bold">
              Identity and Document Verification
            </h3>
            <p className="mb-3">
              Digital identity verification is a primary requirement for every
              Know Your Customer (KYC) process, particularly during the account
              opening and customer onboarding process. By verifying the identity
              of an applicant, organizations such as those in the Financial and
              Fintech markets can run checks to ensure that the applicant is not
              a fraudster, criminal, bad actor, or attempting a scam.
            </p>
            <p className="mb-3">
              Powerdby [AI] runs Identity verification on the following:
              <span className="has-text-white">
                {" "}
                BVN, CAC, National Identification Number (NIN),Tax
                Identification Number (TIN), Voters’ card
              </span>
            </p>
          </div>
          <div className="second-second mb-5">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669117547/Frame_11872_tcoro9.png"
              alt="frame"
            />
          </div>
        </div>
        <div className="second mb-5 grad-box reverse">
          <div className="second-first mb-5">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669117849/Group_11746_kspodg.png"
              alt="frame"
            />
          </div>
          <div className="second-second mb-5">
            <h3 className="mt-4 mb-5 has-text-weight-bold">Object Detection</h3>
            <p className="mb-3">
              Our object detection machine learning models use computer vision
              technique to identify and locate objects within an image or video.
              With a library of pretrained models of one hundred different
              objects. Our algorithm draws the required on bounding boxes around
              the detected objects, which allow your application to locate where
              said objects are in (or how they move through) a given scene.{" "}
              <br />
              <br />
              Powerdby [AI] object detection ML model can identify the
              following:
              <br />
              <br /> <span className="has-text-white">Vehicles:</span> Cars,
              Trucks, Motorcycles, Bicycles, Vans, Busses <br />{" "}
              <span className="has-text-white">Roads:</span> Traffic lights,
              Street lights <br />{" "}
              <span className="has-text-white">Household Objects:</span> Chairs,
              Tables, Beds
            </p>
          </div>
        </div>
        <div className="second mb-5 grad-box">
          <div className="second-first mb-5">
            <h3 className="mt-4 mb-5 has-text-weight-bold">
              License Plate Recognition
            </h3>
            <p className="mb-3">
              License Plate Recognition is the capacity to capture photographic
              video or images from license plates and transform the optical data
              into digital information in real-time. It helps create hassle less
              security, easy parking and billing (tolls), and Vehicle Access
              Control among others.
            </p>
            <p className="mb-3">
              Powerdby [AI]’s license plate recognition feature can be used to
              enhance security, parking and vehicle access control in the
              following:
              <span className="has-text-white">
                {" "}
                Government agencies and parastatals, Private Organizations,
                Estates, Parks and Relaxation Centres, Office Complexes, and
                other high security areas.
              </span>
            </p>
          </div>
          <div className="second-second mb-5">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669118339/Frame_11876_a1r1ss.png"
              alt="frame"
            />
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .second.grad-box {
          background-image: linear-gradient(to right, #0066ff, #0027b2);
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
        }
        .second.grad-box p {
          color: rgba(255, 255, 255, 0.7);
        }
        .image-grid {
          display: grid;
          grid-gap: 1.5rem;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        .image-grid img {
          margin-left: auto;
          margin-right: auto;
        }

        .blue-box {
          background-image: url(https://res.cloudinary.com/analytics-intelligence/image/upload/v1669110978/Mask_group_1_shmllg.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
          padding: 10vh 2rem;
          border-radius: 20px;
        }
        .second {
          background-color: #fff;
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
        }
        .second-first {
          flex-basis: 450px;
          margin-right: 4rem;
        }
        .second-second {
          flex-basis: 450px;
        }
        @media only screen and (max-width: 850px) {
          .second {
            flex-wrap: wrap;
          }
          .second-first {
            flex-grow: 1;
            margin-right: 0px;
          }
          .second-second {
            flex-grow: 1;
          }
          .reverse {
            flex-direction: column-reverse;
          }
          .reverse .second-first {
            flex: 1;
          }
        }

        .second p {
          color: rgb(61, 61, 61, 0.7);
          line-height: 1.7;
        }
        .blue-box .pretrained {
          max-width: 500px;
          color: rgba(255, 255, 255, 0.7);
          line-height: 1.7;
        }
        .blue-box h1 {
          max-width: 550px;
        }
        .home {
          margin-top: 85px;
        }
      `}</style>
    </main>
  );
}
