import { useForm } from "react-hook-form";
import AuthConsumer from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import fetcher from "../utils/fetcher";
import { RESET } from "../utils/apis";

export default function PasswordReset() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { token } = AuthConsumer();
  const onSubmit = (data) => {
    try {
      toast.dismiss();
      const id = toast.loading("Please wait...");
      setLoading(true);

      fetcher(RESET, {
        method: "POST",
        body: JSON.stringify({ ...data }),
        headers: {
          "X-API-KEY": `${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          toast.update(id, {
            render: "Please check your email for password reset link.",
            type: "success",
            isLoading: false,
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(err.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <main className="main-wrapper">
      <div className="container ">
        <div className="columns">
          <div className="white-form column">
            <Link to="/" reloadDocument>
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1662379016/logo.25f888ac_le80rk.svg"
                alt="logo"
                className="logo"
              />
            </Link>
            <h3>Reset your password</h3>
            <img
              className="mt-5 mb-5"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                height: "150px",
              }}
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669284269/Lock_rnfhgz.png"
              alt="reset password"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${errors.email ? "is-danger" : ""}`}
                    type="email"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: true,
                      pattern: /\S+@\S+\.\S+/i,
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                  {errors.email && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </div>
                {errors.email && (
                  <p className="help is-danger">Please provide valid email</p>
                )}
              </div>

              <div className="field">
                <p className="control">
                  <button
                    className={`spread-button full mt-5 button ${
                      loading ? "is-loading" : ""
                    }`}
                  >
                    Reset password
                  </button>
                </p>
              </div>
            </form>
            <div className="already">
              <p>
                Already have an account?{" "}
                <Link to="/login" reloadDocument>
                  log in{" "}
                </Link>
              </p>
            </div>
          </div>
          <div className="column is-hidden-mobile ml-6 bg-img"></div>
        </div>
      </div>

      <style jsx="true">{`
        .splice {
          display: grid;
          margin-top: 2rem;
          margin-bottom: 1rem;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: center;
        }
        .splice p {
          text-align: center;
          color: #d9d9d9;
          font-size: 0.8rem;
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 1;
          z-index: 3;
          background-color: #fff;
          justify-self: center;
          padding-left: 1rem;
          padding-right: 1.5rem;
        }
        .splice .line {
          background-color: #d9d9d9;
          height: 1px;
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 1;
        }
        .bg-img {
          background-image: url(https://res.cloudinary.com/analytics-intelligence/image/upload/v1669284271/Mask_group_1_eo3xuw.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 20px;
        }
        button.with-auth {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          margin-left: auto;
          margin-right: auto;
          font: inherit;
          display: flex;
          font-size: 1rem;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0.6rem 2rem;
          border-radius: 5px;
          font-weight: 500;
          cursor: pointer;
          outline: 0px;
          border: 1px solid #eee;
          box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
        }
        button.with-auth img {
          width: 20px;
          margin-right: 1rem;
        }
        button.with-auth:hover {
          box-shadow: inset 0 100em 100em rgb(10 10 10 / 5%);
        }
        .main-wrapper {
          background-color: #fff;
        }
        .columns {
          padding: 1.5rem;
          margin-left: auto;
          margin-right: auto;
          min-height: 100vh;
        }
        .main-wrapper .white-form {
          width: 100%;
          padding: 1rem;
        }
        .main-wrapper .white-form h3 {
          margin-bottom: 1rem;
          font-weight: 800;
          text-align: center;
        }
        .main-wrapper .white-form .logo {
          width: 150px;
          margin: 0 auto;
        }

        .main-wrapper a {
          color: var(--main-hue);
        }
        .main-wrapper input::placeholder {
          font-family: inherit !important;
        }
        .main-wrapper a:hover {
          text-decoration: underline;
        }
        .already {
          margin-top: 1rem;
          font-size: 0.9rem;
          text-align: center;
        }
      `}</style>
    </main>
  );
}
