import React from 'react'

export default function Thirdsection({mainHeader, paragraph, point1, point2, point3, imgURL, className}) {
  return (
    <div className='bg-[#0165FE] text-white relative rounded-[24px]'>
        <div className=' flex flex-col gap-[30px] lg:w-[70%] w-full px-[43px] py-[27px] items-start'>
            <h1 className='font-[700] md:text-[38px] text-[28px]'>{mainHeader}</h1>
            <p className='font-[400] md:text-[18px] text-[16px]'>{paragraph}</p>
            <div className='mt-[22px] flex flex-col gap-[21px] mb-[50px]'>
                <div className='flex gap-[15px]'>
                    <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678975/Vector_hclwpf.svg" alt="icon" />
                    <span className='font-[500] md:text-[20px] text-[18px]'>{point1}</span>
                </div>
                <div className='flex gap-[15px]'>
                    <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678975/Vector_hclwpf.svg" alt="icon" />
                    <span className='font-[500] md:text-[20px] text-[18px]'>{point2}</span>
                </div>
                <div className='flex gap-[15px]'>
                    <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677678975/Vector_hclwpf.svg" alt="icon" />
                    <span className='font-[500] md:text-[20px] text-[18px]'>{point3}</span>
                </div>
            </div>
        </div>
        <img className={`${className} absolute top-0 right-0 lg:block hidden w-[550px]`} src={imgURL} alt="vector_img" />
    </div>
  )
}
