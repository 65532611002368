const BASEURL = "/api/";

export const SIGNUP = `${BASEURL}organizations/signup`;
export const LOGIN = `${BASEURL}organizations/login`;
export const GET_GOOGLE = `${BASEURL}auth/google/init`;
export const GET_GITHUB = `${BASEURL}auth/github/init`;
export const ME = `${BASEURL}organizations/me`;
export const RESET = `${BASEURL}organizations/reset`;
export const NEWPASSWORD = `${BASEURL}organizations/password`;
export const TOPUP = `${BASEURL}organizations/topup`;
export const TRANSACTIONSTOP = `${BASEURL}organizations/transactions/topup`;
export const TRANSACTIONS = `${BASEURL}organizations/transactions`;
export const UPDATE = `${BASEURL}organizations/update`;
export const UPDATE_PASSWORD = `${BASEURL}organizations/update/password`;
