import { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { toast } from "react-toastify";

export default function Tin() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(null);

  return (
    <div>
      <div className="contain">
        <div className="contain-second prod">
          <form
            method="post"
            encType="multipart/form-data"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                setPayload(null);
                setLoading(true);

                const formData = new FormData(e.target);

                const data = Object.fromEntries(formData);

                let res = await fetch(
                  `/api/idntity/identities/tin/${data.bvn}`,
                  {
                    method: "GET",
                    headers: {
                      "x-api-key": user.testKey,
                      Accept: `application/json, text/plain, */*`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                // if (!res.ok) {
                //   throw new Error("Something went wrong");
                // }
                try {
                  const responseData = await res.json();
                
                  if (responseData.status === "success") {
                    setPayload(responseData.data);
                    return toast.success("Request successful");
                  } else {
                    // Handle server-side errors (e.g., validation errors, request processing errors)
                    const errorMessage = responseData.message;
                    throw new Error(errorMessage);
                  }
                } catch (error) {
                  // Handle JSON parsing errors or other unexpected errors
                  throw new Error(error.message);
                }
              } catch (err) {
                toast.dismiss();
                toast.error(err.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <div className="field">
              <label className="label" htmlFor="bvn">
                Tax Identification Number
              </label>
              <input
                className="input is-normal"
                type="text"
                name="bvn"
                id="bvn"
                placeholder="Tax Identification Number (XXXXXXXX-XXXX)"
                required
                pattern="[0-9]{8}-[0-9]{4}"
                title="Invalid TIN format. Valid TIN should be numbers in this format => XXXXXXXX-XXXX"
                maxLength={13} // Including the hyphen, the total length should be 13 characters
                minLength={13} // Including the hyphen, the total length should be 13 characters
              />
            </div>

            <div className="field">
              <p className="control">
                <button
                  className={`spread-button full mt-5 button ${loading ? "is-loading" : ""
                    }`}
                >
                  Lookup
                </button>
              </p>
            </div>
          </form>
        </div>
        <div className="contain-first prod" style={{ padding: "0px" }}>
          {!loading && payload?.taxpayerName && (
            <div className="results-container  p-5">
              <label className="label mb-2">Personal Details</label>

              <div className="results mt-5 pl-1">
                <p className="has-text-grey-light">Name</p>
                <p>{payload.taxpayerName}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Email</p>
                <p>{payload.email}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">CAC reg. no.</p>
                <p>{payload.cacRegNo}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Entity type</p>
                <p>{payload.entityType}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">TIN</p>
                <p className="is-uppercase">{payload.tin}</p>
              </div>
              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Tax Office</p>
                <p className="is-capitalized">{payload.taxOffice}</p>
              </div>

              <div className="results mt-3 pl-1">
                <p className="has-text-grey-light">Phone Number</p>
                <p>{payload.phone}</p>
              </div>
            </div>
          )}
        </div>
        <style jsx="true">
          {`
            .results {
              display: flex;
              align-items: center;
            }
            .results > p:first-of-type {
              flex-basis: 150px;
              flex-shrink: 0;
            }

            .prod {
              padding: 1rem 2rem;
              border: 1px solid #eee;
              border-radius: 10px;
            }
            .prod img {
              width: 100%;
              height: 100%;
            }
            .contain {
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
              grid-auto-rows: minmax(300px, auto);
              grid-gap: 1rem;
              justify-content: space-between;
              margin-bottom: 1.5rem;
            }
          `}
        </style>
      </div>
    </div>
  );
}
