import React, { useRef, useEffect } from 'react';

function BoundingBox({ coordinates, selectedImage }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Load the image
    const image = new Image();
    image.src = selectedImage;
    // New Image width and height
    const height = 480
    const width = 1140
    // Draw the image on the canvas when it loads
    image.onload = () => {
      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Determine the canvas size based on the aspect ratio and specified width and height
      let canvasWidth, canvasHeight;
      // use new height and width to determine the canvas size
      if (aspectRatio > width / height) {
        canvasWidth = width;
        canvasHeight = width / aspectRatio;
      } else {
        canvasWidth = height * aspectRatio;
        canvasHeight = height;
      }

      // Set the canvas size
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      if(coordinates.length > 0){

        // Draw a bounding box for each coordinate
        coordinates.forEach((coordinate) => {
          // Extract boxes and classes
          const {xmin, ymin, xmax, ymax} = coordinate.bbox; 
          const text = coordinate.object; 
          // Update the bounding box coordinates based on the new canvas size
          const x = xmin * canvas.width / image.width;
          const y = ymin * canvas.height / image.height;
          const w = (xmax-xmin) * canvas.width / image.width;
          const h = (ymax-ymin) * canvas.height / image.height;
          ctx.beginPath();
          ctx.lineWidth = 4;
          // ctx.fillStyle = '#FF0000'; // Red color
          ctx.font = '16px Arial';
          // ctx.textAlign = 'center';
          // ctx.fillText(text, x + width / 2, y - 5);
          ctx.strokeStyle = '#FF0000'; // Red color
          ctx.rect(x, y, w, h);
          ctx.stroke();
  
          const labelWidth = ctx.measureText(text).width + 10;
          const labelHeight = 20;
          const labelX = x;
          const labelY = y - 20;
  
          // Draw the background rectangle
          ctx.fillStyle = '#FF0000'; // Red color
          ctx.fillRect(labelX-2, labelY, labelWidth, labelHeight);
  
          // Draw the label text
          ctx.fillStyle = '#FFFFFF'; // White color
          ctx.fillText(text, labelX, y-5);
        });
      }
    };
  }, [coordinates, selectedImage]);

  return (
    <canvas ref={canvasRef} style={{marginLeft:"auto", marginRight: 'auto'}} />
  );
}

export default BoundingBox;
