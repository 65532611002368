import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { UPDATE, UPDATE_PASSWORD } from "../utils/apis";
import fetcher from "../utils/fetcher";
import { toast } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";

const tabs = [
  { key: "profile", text: "Profile" },
  { key: "password", text: "Change Password" },
];

export default function Settings() {
  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  return (
    <div className="container  p-5">
      <h3 className="greeting">Settings</h3>
      <form action="#">
        <div className="slider">
          {tabs.map((t) => (
            <NavLink
              to={`/app/settings/${t.key}`}
              className={({ isActive }) =>
                isActive ? `slide-item scroller` : "slide-item"
              }
              key={t.key}
            >
              {t.text}
            </NavLink>
          ))}
        </div>
      </form>
      <div className="houser">
        <Routes>
          <Route path="/password" exact element={<Password />} />
          <Route path="/*" exact element={<Profile />} />
        </Routes>
      </div>
      <style jsx="true">
        {`
          .houser {
            background-color: #fff;
            border-radius: 20px;
            padding: 2rem;
          }

          .slider {
            margin: 1rem 0px;
            display: flex;
            position: relative;
          }

          .slide-item {
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: transparent;
            position: relative;
            z-index: 10;
            color: #0047ff;
            font-weight: bold;
            cursor: pointer;
            flex-basis: 150px;
            flex-shrink: 0;
          }

          .slide-item.scroller {
            background-color: #fff;

            border-radius: 10px;
          }
          .greeting {
            font-weight: 800;
          }
          h4.greeting {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: 300;
          }
        `}
      </style>
    </div>
  );
}

function Profile() {
  const { user, token } = useAuth();
  const [loadPersonal, setLoadPersonal] = useState(false);
  const [loadOrg, setLoadOrg] = useState(false);

  return (
    <div className="products">
      <div className="product">
        <h5>Personal Details</h5>
        <form
          onSubmit={async (e) => {
            try {
              e.preventDefault();
              toast.dismiss();
              const id = toast.loading("Please wait...");
              setLoadPersonal(true);
              const data = Object.fromEntries(new FormData(e.target));
              fetcher(UPDATE, {
                method: "PUT",
                headers: {
                  "X-API-KEY": `${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...data }),
              })
                .then((res) => {
                  toast.update(id, {
                    render: "Successfully updated profile",
                    type: "success",
                    isLoading: false,
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.reload();
                })
                .catch((err) => {
                  toast.dismiss();
                  toast.error(err.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            } catch (error) {
              setLoadPersonal(false);
              toast.dismiss();
              toast.error(error.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }}
        >
          <div className="field">
            <label className="label">Full name</label>

            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="text"
                defaultValue={user?.name}
                name="name"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label">Email</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="email"
                defaultValue={user?.email}
                readOnly
                disabled
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Phone number</label>
            <p className="control has-icons-left has-icons-right">
              <input
                defaultValue={user.phoneNumber}
                className={`input`}
                type="tel"
                placeholder="Enter your phone number"
                minLength={11}
                maxLength={11}
                name="phoneNumber"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-phone"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button
                className={`spread-button full mt-5 button ${
                  loadPersonal ? "is-loading" : ""
                }`}
              >
                Save
              </button>
            </p>
          </div>
        </form>
      </div>
      <div className="product">
        <h5>Organization Details</h5>
        <form
          onSubmit={async (e) => {
            try {
              e.preventDefault();
              toast.dismiss();
              const id = toast.loading("Please wait...");
              setLoadOrg(true);

              const data = Object.fromEntries(new FormData(e.target));

              fetcher(UPDATE, {
                method: "PUT",
                headers: {
                  "X-API-KEY": `${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...data }),
              })
                .then((res) => {
                  toast.update(id, {
                    render: "Successfully updated profile",
                    type: "success",
                    isLoading: false,
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.reload();
                })
                .catch((err) => {
                  setLoadOrg(false);
                  toast.dismiss();
                  toast.error(err.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            } catch (error) {
              toast.dismiss();
              toast.error(error.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }}
        >
          <div className="field">
            <label className="label">Business name</label>

            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="text"
                defaultValue={user?.businessName}
                name="businessName"
                placeholder="Please enter your business name"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-business-time"></i>
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label">Business Address</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="text"
                placeholder="Please enter your business address"
                defaultValue={user?.businessAddress}
                name="businessAddress"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-location-pin"></i>
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Registration number</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="text"
                placeholder="Enter your CAC number"
                name="registrationNumber"
                defaultValue={user?.registrationNumber}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-hashtag"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button
                className={`spread-button full mt-5 button ${
                  loadOrg ? "is-loading" : ""
                }`}
              >
                Save
              </button>
            </p>
          </div>
        </form>
      </div>
      <style jsx="true">
        {`
          .products {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-auto-rows: minmax(300px, auto);
            grid-gap: 1rem;
            justify-content: space-between;
          }
          .products .product {
            padding: 1rem 2rem;
            border: 1px solid #eee;
            border-radius: 20px;
          }
          .products .product h5 {
            font-weight: 900;
            margin-bottom: 1.5rem;
            text-transform: capitalize;
          }
          .products .product p {
            font-size: 1rem;
          }
          .products .product a {
            color: var(--main-hue);
            text-decoration: underline;
            display: inline-block;
            margin-top: 1rem;
          }
        `}
      </style>
    </div>
  );
}

function Password() {
  const { token, user } = useAuth();
  const [loadPersonal, setLoadPersonal] = useState(false);
  return (
    <div className="products">
      <div className="product">
        <h5>Change Password</h5>
        <form
          onSubmit={async (e) => {
            if (user.isPasswordless) {
              return;
            }
            try {
              e.preventDefault();
              toast.dismiss();
              const id = toast.loading("Please wait...");
              setLoadPersonal(true);
              const data = Object.fromEntries(new FormData(e.target));
              if (data.newPassword !== data.confirmPassword)
                throw new Error("Please confirm new password");
              fetcher(UPDATE_PASSWORD, {
                method: "PUT",
                headers: {
                  "X-API-KEY": `${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...data }),
              })
                .then((res) => {
                  toast.update(id, {
                    render: "Successfully updated profile",
                    type: "success",
                    isLoading: false,
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.reload();
                })
                .catch((err) => {
                  toast.dismiss();
                  toast.error(err.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            } catch (error) {
              setLoadPersonal(false);
              toast.dismiss();
              toast.error(error.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }}
        >
          <div className="field">
            <label className="label">Old password</label>

            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="password"
                placeholder="Please enter old password"
                name="oldPassword"
                disabled={user.isPasswordless}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-lock"></i>
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">New password</label>

            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="password"
                placeholder="Please enter new password"
                name="newPassword"
                disabled={user.isPasswordless}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-lock"></i>
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Confirm new password</label>

            <div className="control has-icons-left has-icons-right">
              <input
                className={`input`}
                type="password"
                placeholder="Please confirm new password"
                name="confirmPassword"
                disabled={user.isPasswordless}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-lock"></i>
              </span>
            </div>
          </div>

          <div className="field">
            <p className="control">
              <button
                className={`spread-button full mt-5 button ${
                  loadPersonal ? "is-loading" : ""
                }`}
                disabled={user.isPasswordless}
              >
                Change password
              </button>
            </p>
          </div>
        </form>
      </div>
      <div className="product" style={{ border: "0px" }}></div>
      <style jsx="true">
        {`
          .products {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-auto-rows: minmax(300px, auto);
            grid-gap: 1rem;
            justify-content: space-between;
          }
          .products .product {
            padding: 1rem 2rem;
            border: 1px solid #eee;
            border-radius: 20px;
          }
          .products .product h5 {
            font-weight: 900;
            margin-bottom: 1.5rem;
            text-transform: capitalize;
          }
          .products .product p {
            font-size: 1rem;
          }
          .products .product a {
            color: var(--main-hue);
            text-decoration: underline;
            display: inline-block;
            margin-top: 1rem;
          }
        `}
      </style>
    </div>
  );
}
