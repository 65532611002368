import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";
import { TRANSACTIONSTOP } from "../utils/apis";
import fetcher from "../utils/fetcher";
import { ThreeDots } from "react-loader-spinner";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  Badge,
  Card,
  Datepicker,
} from "@tremor/react";

const colors = {
  "Ready for dispatch": "gray",
  deduction: "rose",
  deposit: "emerald",
};

const colorsStatus = {
  "Ready for dispatch": "gray",
  failed: "rose",
  success: "emerald",
};

export default function Wallet() {
  const { user, token, setDisplayPaymentModal } = useAuth();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const [start, setStart] = useState(new Date(new Date().getFullYear() - 1));
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    const txRef = new URLSearchParams(window.location.search).get("tx_ref");
    const amount = new URLSearchParams(window.location.search).get("amount");
    const status = new URLSearchParams(window.location.search).get("status");

    if (txRef && amount && status === "successful") {
      const urlMain = new URL(window.location);

      urlMain.search = "";
      window.history.replaceState(null, "New Page Title", urlMain.pathname);
      toast.dismiss();
      toast.success(
        `Congratulations 🎉,${new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
        }).format(Number(amount))} has been added to your wallet`,
        {
          type: "success",
          isLoading: false,
          position: "top-right",

          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }, []);
  useEffect(() => {
    const fetchTransactions = () => {
      setLoading(true);
      // Format start and end dates to local date strings without timezone offset
      const formattedStart = new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString();
      const formattedEnd = new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString();
      fetcher(
        `${TRANSACTIONSTOP}?page=${page}&start=${formattedStart}&end=${formattedEnd}`,
        {
          method: "GET",
          headers: {
            "X-API-KEY": `${token}`,
            "Content-Type": "application/json",
          },
        }
      ).then(({ transactions }) => {
        if (transactions) {
          setData(transactions);
        }
      }
      )
        .catch(console.log)
        .finally(() => setLoading(false));
    };
    fetchTransactions();
  }, [page, setData, setLoading, token, start, end]);
  return (
    <div className="container  p-5">
      <h3 className="greeting mb-5">Wallet</h3>

      <div className="product mb-5">
        <div className="mr-3">
          <h3 className="mb-5">Balance</h3>
          <h5>
            {new Intl.NumberFormat("en-NG", {
              style: "currency",
              currency: "NGN",
            }).format(Number(user.balance))}
          </h5>
        </div>
        <button
          onClick={() => setDisplayPaymentModal(true)}
          className="spread-button mt-3 mb-3"
          style={{
            color: "#0066ff",
            backgroundColor: "#fff",
          }}
        >
          Top up wallet
        </button>
      </div>

      <h6 className="greeting mb-2">Transactions history</h6>
      <Datepicker
        placeholder="Select..."
        enableRelativeDates={true}
        handleSelect={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        defaultStartDate={start}
        defaultEndDate={end}
        defaultRelativeFilterOption={null}
        minDate={null}
        maxDate={end}
        color="blue"
        maxWidth="max-w-xs"
        marginTop="mt-5"
      />

      <div className="houser mt-5">
        {loading ? (
          <div className="center">
            <ThreeDots
              height="100"
              width="200"
              radius="9"
              color="#334d8c"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </div>
        ) : (
          <Card marginTop="mt-6">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>SERVICES</TableHeaderCell>
                  <TableHeaderCell>AMOUNT</TableHeaderCell>
                  <TableHeaderCell>PAYMENT TYPE</TableHeaderCell>
                  <TableHeaderCell>STATUS</TableHeaderCell>
                  <TableHeaderCell>DATE CREATED</TableHeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.service}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }).format(Number(item.amount))}
                    </TableCell>
                    <TableCell>
                      <Badge color={colors[item.type]} text={getTypeText(item.type)} size="xs" />
                    </TableCell>
                    <TableCell>
                      <Badge color={colorsStatus[item.status]} text={item.status.replace("success", "SUCCESSFUL").toUpperCase()} size="xs" />
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(item.createdAt))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}
        <div
          className="field has-addons mt-5 mr-5"
          style={{ justifyContent: "flex-end" }}
        >
          <p className="control previous">
            <button
              className="button"
              disabled={Number(page) === 1}
              onClick={() => {
                setPage((p) => Math.max(p - 1, 1));
              }}
            >
              <span className="icon is-small">
                <i className="fa-solid fa-angle-left"></i>
              </span>
              <span>Previous</span>
            </button>
          </p>

          <p className="control next">
            <button
              className="button"
              disabled={page * 10 >= user.transactions || data.length < 10}
              onClick={() => {
                if (data.length >= 10) {
                  setPage((p) => p + 1);
                }
              }}
            >
              <span>Next</span>
              <span className="icon is-small">
                <i className="fa-solid fa-angle-right"></i>
              </span>
            </button>
          </p>
        </div>
      </div>
      <style jsx="true">
        {`
          .product {
            padding: 2rem;
            border-radius: 20px;
            color: #fff;
            background-image: url(https://res.cloudinary.com/analytics-intelligence/image/upload/v1669157481/Group_11747_cklni7.png);
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          }

          .product .guards {
            position: absolute;
            right: 40px;
            flex-basis: 300px;
          }
          @media only screen and (max-width: 960px) {
            .product .guards {
              display: none;
            }
          }
          .product h5 {
            font-weight: 900;
            text-transform: capitalize;
          }
          .houser {
            background-color: #fff;
            border-radius: 20px;
            padding: 2rem;
          }
          .previous button,
          .next button {
            background-color: transparent;
            border: 0px;
            outline: 0px;
            color: var(--main-hue);
          }

          .greeting {
            font-weight: 800;
          }
          h4.greeting {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: 300;
          }
          .center {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
          }
          .box-wallet {
            color: var(--main-hue);
          }
          .box-wallet .first-wallet {
            background-color: rgba(250, 250, 250, 1);
            border-radius: 10px;
            padding: 1rem;
            margin-top: 2rem;
            border: 2px solid var(--main-hue);
            display: inline-block;
          }
          .box-wallet button {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            margin-left: 25px;
            color: var(--main-hue);
            font-weight: 800;
            font: inherit;
            border: 0px;
            outline: 0px;
            background-color: transparent;
            font-size: 1rem;
            cursor: pointer;
          }
          .box-wallet button span {
            margin-left: 0.5rem;
            font-weight: 800;
          }
          .first-wallet h3 {
            font-weight: 800;
            letter-spacing: -1px;
          }
          .cover {
            border: 2px solid var(--main-hue);
            border-radius: 100000%;
            font-size: 14px;
            line-height: 16px;
            width: 20px;
            height: 20px;
            text-align: center;
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
}

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

const formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  // Extract the day and remove any suffix for 1st, 2nd, 3rd, etc.
  const day = date.getDate();
  const suffix =
    day >= 11 && day <= 13
      ? "th"
      : { 1: "st", 2: "nd", 3: "rd" }[day % 10] || "th";

  // Replace the day in the formatted date with the day and suffix
  const modifiedDate = formattedDate.replace(/\d{1,2}/, (day + suffix));

  return `${modifiedDate}, ${formatTime(date)}`;
};

const getTypeText = (type) => {
  if (type === "deposit") return "CREDIT";
  if (type === "deduction") return "DEBIT";
  return type;
};

