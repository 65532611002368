import React from 'react'
import { Link } from 'react-router-dom'

export default function Firstsection({tag, mainHeader, paragraph, getStartedLink, bookADemoLink, imgURL, imgURL2, className, width}) {
  return (
    <>
        <div className='background rounded-[24px] relative'>
            <div className={`${width} flex flex-col gap-[40px] lg:w-[50%] w-full px-[43px] py-[27px] items-start`}>
                <span 
                    className='text-[#6D6E71] text-[12px] px-[15.5px] py-[5.5px] bg-white rounded-[10px]'
                >{tag}</span>
                <div className='flex flex-col gap-[14px] text-[#ffff]'>
                    <h1 className='font-[700] md:text-[37px] text-[28px]'>{mainHeader}</h1>
                    <p className='font-[400] md:text-[18px] text-[16px]'>{paragraph}</p>
                </div>
                <div className='flex gap-[19px]'>
                    <Link className='text-[12px] sm:text-[16px] py-[12px] sm:px-[24px] px-[12px] rounded-[12px] text-[#0047FF] hover:text-[#0047FF] hover:opacity-70 bg-white' to='/app/idntity/playground' reloadDocument> Get Started</Link>
                    <Link className='text-[12px] sm:text-[16px] py-[12px] sm:px-[24px] px-[12px] rounded-[12px] border border-white  bg-transparent text-[#fff] hover:text-[#fff] hover:opacity-70' to='/app/idntity/playground' reloadDocument> Book a Demo</Link>
                </div>
            </div>
            <img className={`${className} absolute right-0 lg:block hidden`} src={imgURL} alt="" />
            {imgURL2 && <img className={`absolute right-[370px] top-[175px] lg:block hidden`} src={imgURL2} alt="" />}
        </div>
        <style jsx='true'>
            {
                `
                .background{
                    background: linear-gradient(95.28deg, #0066FF 1.93%, #0027B3 43.16%);
                }
                `
            }
        </style>
    </>
  )
}
