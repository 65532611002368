export default function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.69199 11.897L9.10199 12.367C10.034 12.677 10.499 12.833 10.833 13.167C11.167 13.501 11.323 13.967 11.633 14.897L12.103 16.307C12.887 18.661 13.279 19.837 14 19.837C14.72 19.837 15.113 18.661 15.897 16.307L18.735 7.79499C19.287 6.13899 19.563 5.31099 19.126 4.87399C18.689 4.43699 17.861 4.71299 16.206 5.26399L7.69099 8.10399C5.33999 8.88699 4.16199 9.27899 4.16199 9.99999C4.16199 10.721 5.33899 11.113 7.69199 11.897Z"
        fill="#0066ff"
        fill-opacity="0.25"
      />
      <path
        d="M7.69199 11.897L9.10199 12.367C10.034 12.677 10.499 12.833 10.833 13.167C11.167 13.501 11.323 13.967 11.633 14.897L12.103 16.307C12.887 18.661 13.279 19.837 14 19.837C14.72 19.837 15.113 18.661 15.897 16.307L18.735 7.79499C19.287 6.13899 19.563 5.31099 19.126 4.87399C18.689 4.43699 17.861 4.71299 16.206 5.26399L7.69099 8.10399C5.33999 8.88699 4.16199 9.27899 4.16199 9.99999C4.16199 10.721 5.33899 11.113 7.69199 11.897Z"
        fill="#0066ff"
        fill-opacity="0.25"
      />
      <path
        d="M9.52602 13.842L7.46402 13.155C7.31973 13.1069 7.16637 13.0923 7.01561 13.1124C6.86485 13.1325 6.72066 13.1868 6.59402 13.271L5.50402 13.997C5.37499 14.083 5.27372 14.2046 5.21253 14.347C5.15133 14.4895 5.13286 14.6467 5.15934 14.7994C5.18583 14.9522 5.25613 15.094 5.36171 15.2075C5.46728 15.3211 5.60358 15.4015 5.75402 15.439L7.70902 15.927C7.79699 15.949 7.87734 15.9944 7.94146 16.0585C8.00558 16.1227 8.05105 16.203 8.07302 16.291L8.56102 18.246C8.59853 18.3964 8.67895 18.5327 8.7925 18.6383C8.90605 18.7439 9.04782 18.8142 9.20059 18.8407C9.35336 18.8672 9.51053 18.8487 9.65299 18.7875C9.79544 18.7263 9.91705 18.625 10.003 18.496L10.729 17.406C10.8132 17.2793 10.8675 17.1352 10.8876 16.9844C10.9077 16.8336 10.8931 16.6803 10.845 16.536L10.158 14.474C10.1089 14.3268 10.0262 14.1931 9.91656 14.0835C9.80687 13.9738 9.67316 13.8911 9.52602 13.842Z"
        fill="#0066ff"
      />
    </svg>
  );
}
