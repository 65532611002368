import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Chatbot from "../../components/chatBot";

export default function ComputerVision() {
  return (
    <div>
      <Header />
      <div className="bg-[#f8f8f8] mt-[70px] pt-[70px] flex flex-col gap-[150px]">
        <div className="background rounded-[24px] relative w-[85%] mx-auto">
          <div
            className={`lg:w-[50%] w-full flex flex-col gap-[40px] px-[43px] pt-[64px] pb-[27px] items-start`}
          >
            <div className="flex flex-col gap-[14px] text-[#ffff]">
              <h1 className="font-[700] md:text-[37px] text-[28px]">
                PerceptionX
              </h1>
              <p className="font-[400] md:text-[18px] text-[16px]">
                Leverage the latest advances in AI and computer vision to help
                you identify objects and analyze visual data with accuracy and
                speed. Our state-of-the-art algorithms are trained on vast
                amounts of data, allowing Perception X to deliver highly
                accurate results in real time.
              </p>
            </div>
            <div className="flex gap-[19px]">
              <Link
                className="text-[14px] md:text-[16px] py-[12px] px-[24px] rounded-[12px] text-[#0047FF] hover:text-[#0047FF] hover:opacity-70 bg-white"
                to="/signup"
              >
                {" "}
                Get Started
              </Link>
            </div>
          </div>
          <img
            className={`top-[-20px] right-0 absolute right-0 lg:block hidden`}
            src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677747414/Group_11771_1_umxqql.svg"
            alt="laptop"
          />
        </div>
        <div className="flex flex-col gap-[180px] w-[85%] mx-auto">
          <div className="flex lg:flex-row flex-col justify-center items-center gap-[50px] px-[50px]">
            <div className="flex flex-col gap-[15px] lg:w-[50%] w-full">
              <h1 className="text-[#0044F1] md:text-[40px] text-[30px] font-[700]">
                Object Detection
              </h1>
              <p className="leading-8 md:text-[16px] text-[14px]">
                Our object detection machine learning models use computer vision
                technique to identify and locate objects within an image or
                video. With a library of pretrained models of one hundred
                different objects. Our algorithm draws the required on bounding
                boxes around the detected objects, which allow your application
                to locate where said objects are in (or how they move through) a
                given scene.
              </p>
              <p className="text-[#0044F1] md:text-[16px] text-[14px]">
                Powerdby[AI] object detection ML model can identify the
                following: 
              </p>
              <ul className="list-disc pl-[25px] flex flex-col gap-[5px] md:text-[16px] text-[14px]">
                <li>
                  <b>Vehicles:</b>Cars, Trucks, Motorcycles, Bicycles, Vans,
                  Busses
                </li>
                <li>
                  <b>Roads:</b>Traffic lights, Street lights Households
                </li>
                <li>
                  <b>Objects:</b>Chairs, Tables, Beds
                </li>
              </ul>
            </div>
            <div className="lg:w-[50%] w-full">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678095619/Group_11780_1_gkisej.jpg"
                alt="object detection"
              />
            </div>
          </div>
          <div className="flex lg:flex-row-reverse flex-col justify-center items-center gap-[50px] px-[50px]">
            <div className="flex flex-col gap-[15px] lg:w-[50%] w-full">
              <h1 className="text-[#0044F1] md:text-[40px] text-[30px] font-[700]">
                License Plate Recognition
              </h1>
              <p className="leading-8 md:text-[16px] text-[14px]">
                License Plate Recognition is the capacity to capture
                photographic video or images from license plates and transform
                the optical data into digital information in real-time. It helps
                create hassle less security, easy parking and billing (tolls),
                and Vehicle Access Control among others. Powerdby[AI]’s license
                plate recognition feature can enhance security, parking, and
                vehicle access control in government agencies and parastatals,
                Private Organizations, Estates, Parks and Relaxation enters,
                Office Complexes, and other high-security areas.
              </p>
            </div>
            <div className="lg:w-[50%] w-full">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678095628/Group_11783_1_zhfbk6.jpg"
                alt="license plate detection"
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-center items-center gap-[50px] px-[50px]">
            <div className="flex flex-col gap-[15px] lg:w-[50%] w-full">
              <h1 className="text-[#0044F1] md:text-[40px] text-[30px] font-[700]">
                Gender Detection
              </h1>
              <p className="leading-8 md:text-[16px] text-[14px]">
                Gender detection refers to the process of determining a person's
                gender based on various attributes such as name, voice, text, or
                image. There are various methods to perform gender detection,
                including:
              </p>
              <ul className="list-disc pl-[25px] flex flex-col gap-[2px] leading-8 md:text-[16px] text-[14px]">
                <li>
                  Name-based detection: Here, the gender is determined based on
                  the first name of a person. This method is prone to errors and
                  is not very accurate.
                </li>
                <li>
                  Voice-based detection: Here, the gender is determined based on
                  the voice of a person. This method requires a recording of a
                  person's voice and is more accurate than name-based detection.
                </li>
                <li>
                  Text-based detection: Here, the gender is determined based on
                  the text written by a person. This method is used in natural
                  language processing and is relatively accurate.
                </li>
                <li>
                  Image-based detection: Here, the gender is determined based on
                  the image of a person. This method uses computer vision and
                  machine learning algorithms to detect facial features and
                  other attributes to determine gender.
                </li>
              </ul>
            </div>
            <div className="lg:w-[50%] w-full">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678095640/Group_11778_1_qovmmv.jpg"
                alt="gender detection"
              />
            </div>
          </div>
          <div className="flex lg:flex-row-reverse flex-col justify-center items-center gap-[50px] px-[50px]">
            <div className="flex flex-col gap-[15px] lg:w-[50%] w-full">
              <h1 className="text-[#0044F1] md:text-[40px] text-[30px] font-[700]">
                Emotion Detection
              </h1>
              <ul className="list-disc pl-[25px] flex flex-col gap-[2px] leading-8 md:text-[16px] text-[14px]">
                <li>
                  Emotion detection, also known as sentiment analysis, is the
                  process of identifying and extracting emotional information
                  from a given source such as text, speech, or facial
                  expressions. It aims to recognize emotions like happiness,
                  sadness, anger, fear, surprise, and disgust. The goal is to
                  understand how people feel about a particular subject or
                  event.
                </li>
                <li>
                  This technology has several applications, including customer
                  service, market research, and social media monitoring. For
                  example, companies can use emotion detection to monitor
                  customer satisfaction with their products, and use this
                  information to improve customer experiences. It can also be
                  used to analyze public opinion about a particular issue or
                  product, providing valuable insights for decision-makers.
                </li>
                <li>
                  Emotion detection algorithms typically use natural language
                  processing (NLP) techniques and machine learning models to
                  classify emotional states based on the input. The models are
                  trained on large datasets of text and/or audio recordings,
                  labeled with the corresponding emotions. The performance of
                  these models can vary, and it's important to consider the
                  context and cultural differences when interpreting the
                  results.
                </li>
              </ul>
            </div>
            <div className="lg:w-[50%] w-full">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678095652/Group_11779_1_opacb6.jpg"
                alt="emotion detection"
              />
            </div>
          </div>
        </div>
        <div className="bg-[#0165FE] flex flex-col gap-[34px] pt-[37px] pb-[56px] text-white items-center">
          <div className="w-[80%] mx-auto text-center max-w-[600px] leading-8">
            <p>
              In conclusion, emotion detection is a rapidly growing field with
              great potential for improving human-computer interactions and
              understanding human emotions on a large scale.
            </p>
          </div>
          <Link
            className="py-[12px] px-[67px] rounded-[12px] text-[#0047FF] font-[500] hover:text-[#0047FF] hover:opacity-70 bg-white"
            to="/app/idntity/playground"
            reloadDocument
          >
            {" "}
            Get Started
          </Link>
        </div>
      </div>
      <Chatbot />
      <Footer />
      <style jsx="true">
        {`
          .background {
            background: linear-gradient(
              95.28deg,
              #0066ff 1.93%,
              #0027b3 43.16%
            );
          }
        `}
      </style>
    </div>
  );
}
