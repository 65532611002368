import * as React from "react";
import fetcher from "../utils/fetcher";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { SIGNUP, LOGIN, ME } from "./../utils/apis";

const authContext = React.createContext();

function useAuth() {
  let navigate = useNavigate();
  const [token, setToken] = React.useState(Cookies.get("ai-token"));

  const [authed, setAuthed] = React.useState(Boolean(token));
  const [user, setUser] = React.useState({
    _id: "",
    name: "",
    email: "",
    isActive: false,
    country: "",
    createdAt: "",
    updatedAt: "",
    balance: 0,
  });
  const [displayPaymentModal, setDisplayPaymentModal] = React.useState(false);
  React.useEffect(() => {
    if (token) {
      fetcher(ME, {
        method: "GET",
        headers: {
          "X-API-KEY": `${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((body) => {
        if (body.failure) {
          Cookies.remove("ai-token");
          setToken(undefined);
          setAuthed(false);
          navigate("/login", { replace: true });
        }
        setUser((s) => ({ ...s, ...body.organization }));
      });
    }
    return () => {
      setUser({
        _id: "",
        nameame: "",
        email: "",
        isActive: false,
        country: "",
        createdAt: "",
        updatedAt: "",
        balance: 0,
      });
    };
  }, [setUser, token, navigate]);

  return {
    displayPaymentModal,
    setDisplayPaymentModal,
    user,
    setUser,
    token,
    authed,
    async login(state = {}) {
      const body = await fetcher(LOGIN, {
        method: "POST",
        body: JSON.stringify(state),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (body.failure) {
        throw body.message;
      }
      setToken(body.token);
      setAuthed(true);

      Cookies.set("ai-token", body.token, { expires: 1 });

      return body;
    },
    async signup(state = {}) {
      const body = await fetcher(SIGNUP, {
        method: "POST",
        body: JSON.stringify({
          ...state,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (body.failure) {
        throw body.message;
      }

      return body;
    },
    logout() {
      Cookies.remove("ai-token");
      setToken(undefined);
      setAuthed(false);
      navigate("/login", { replace: true });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
