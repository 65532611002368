import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Firstsection from "../../components/marketingpages/Firstsection";
import Lastsection from "../../components/marketingpages/Lastsection";
import Secondsection from "../../components/marketingpages/Secondsection";
import Thirdsection from "../../components/marketingpages/Thirdsection";
import Chatbot from "../../components/chatBot";

export default function IDV() {
  return (
    <div>
      <Header />
      <div className="bg-[#f8f8f8] mt-[70px] mb-[64px] pt-[70px] flex flex-col gap-[150px] w-[85%] mx-auto">
        <Firstsection
          tag="Identity Document Verification"
          mainHeader="Easily verify government issued IDs"
          paragraph="Securely verify data provided by users to match national identity databases with our APIs"
          getStartedLink="/"
          bookADemoLink="/"
          imgURL="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677679720/Group_2_f2knse.svg"
          imgURL2="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677681570/3d-illustration-set-green-red-shield_1_s8qfxo.svg"
          className="top-[37px] h-[355px]"
        />
        <Secondsection
          header1="Expand your user base"
          label1="Expand your customer base, tap into new markets, and ultimately drive revenue growth."
          icon1="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677679711/users_1_armuon.svg"
          header2="Seamless Integration, Multiple Databases"
          label2="Gain access to multiple identity databases with just one integration."
          icon2="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677679704/meeting_1_jetvpa.svg"
          header3="Streamlined Onboarding, Maximum Adoption"
          label3="Minimize user drop-offs during the onboarding process and accelerate user adoption."
          icon3="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677679696/cloud-server_1_nbgpkt.svg"
        />
        <Thirdsection
          mainHeader="Quickly onboard credible customers with our identity verification service "
          paragraph="PowerdbyAI lets you genuinely sign-up users with verified KYC profile"
          point1="Onboard users quickly with their government approved ID"
          point2="Match user uploaded photos to official ID photos"
          point3="Verify the authenticity of physical documents digitally"
          imgURL="https://res.cloudinary.com/analytics-intelligence/image/upload/v1677679688/Group_3_huyd6i.svg"
          className="right-[-50px] top-[20px]"
        />
        <Lastsection />
      </div>
      <Chatbot />
      <Footer />
    </div>
  );
}
