import React from "react";

export const DislikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.666707 9.33331C0.666707 9.68693 0.807183 10.0261 1.05723 10.2761C1.30728 10.5262 1.64642 10.6666 2.00004 10.6666H6.21337L5.57337 13.7133C5.56004 13.78 5.55337 13.8533 5.55337 13.9266C5.55337 14.2 5.66671 14.4533 5.84671 14.6333L6.55337 15.3333L10.94 10.9466C11.1867 10.7 11.3334 10.3666 11.3334 9.99998V3.33331C11.3334 2.97969 11.1929 2.64055 10.9428 2.3905C10.6928 2.14046 10.3537 1.99998 10 1.99998H4.00004C3.44671 1.99998 2.97337 2.33331 2.77337 2.81331L0.76004 7.51331C0.70004 7.66665 0.666707 7.82665 0.666707 7.99998V9.33331ZM15.3334 1.99998H12.6667V9.99998H15.3334V1.99998Z"
        fill="#0066ff"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6667 10V2.00001H15.3334V10H12.6667ZM10 2.00001C10.3537 2.00001 10.6928 2.14049 10.9428 2.39053C11.1929 2.64058 11.3334 2.97972 11.3334 3.33334V10C11.3334 10.3667 11.1867 10.7 10.94 10.94L6.55337 15.3333L5.84671 14.6267C5.66671 14.4467 5.55337 14.2 5.55337 13.9267L5.57337 13.7133L6.20671 10.6667H2.00004C1.64642 10.6667 1.30728 10.5262 1.05723 10.2762C0.807183 10.0261 0.666707 9.68697 0.666707 9.33334V8.00001C0.666707 7.82668 0.70004 7.66668 0.76004 7.51334L2.77337 2.81334C2.97337 2.33334 3.44671 2.00001 4.00004 2.00001H10ZM10 3.33334H3.98004L2.00004 8.00001V9.33334H7.86004L7.10671 12.88L10 9.98001V3.33334Z"
        fill="#0066ff"
      />
    </svg>
  );
};
