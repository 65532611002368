import useAuth from "./../hooks/useAuth";
import { useLocation, Navigate } from "react-router-dom";

export function RequireAuth({ children }) {
  const { authed } = useAuth();
  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export function ToDashboard({ children }) {
  const { authed } = useAuth();

  return authed === true ? <Navigate to="/app" replace /> : children;
}
