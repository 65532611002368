export default function Terms() {
  return (
    <div className="container  p-5" style={{ marginTop: "85px" }}>
      <h1 className="mt-4">Terms of service</h1>
      <p className="bold mt-5">
        These terms and conditions outline the rules and regulations for the use
        of the Analytics Intelligence Sites. ‘Analytics Intelligence Sites’
        refers to all websites, desktop and mobile apps of Analytics
        Intelligence
      </p>
      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Analytics Intelligence sites if you
        do not agree to take all of the terms and conditions stated on this
        page.
      </p>
      <h3>Cookies</h3>
      <p className="mt-5">
        We employ the use of cookies. By accessing Analytics Intelligence sites,
        you agreed to use cookies in agreement with the Analytics Intelligence's
        Privacy Policy. <br />
        Most interactive websites use cookies to let us retrieve the user's
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>
      <h3>License</h3>
      <p className="mt-5">
        Unless otherwise stated, Analytics Intelligence and/or its licensors own
        the intellectual property rights for all material on Analytics
        Intelligence sites. All intellectual property rights are reserved. You
        may access this from Analytics Intelligence sites for your personal use
        subject to restrictions set in these terms and conditions.
      </p>
      <h2 className="mt-4 mb-4" style={{ marginTop: ".8rem" }}>
        You must not:
      </h2>
      <ul>
        <li>Republish material from Analytics Intelligence sites</li>
        <li>
          Sell, rent, or sub-license material from Analytics Intelligence sites
        </li>
        <li>
          Reproduce, duplicate or copy material from Analytics Intelligence
          sites
        </li>
        <li>
          Redistribute content from Analytics Intelligence sites. This Agreement
          shall begin on the date hereof. Parts of this website offer an
          opportunity for users to post and exchange opinions and information in
          certain areas of the website. Analytics Intelligence does not filter,
          edit, publish or review Comments before their presence on the website.
          Comments do not reflect the views and opinions of Analytics
          Intelligence, its agents, and/or affiliates. Comments reflect the
          views and opinions of the person who posts their views and opinions.
          To the extent permitted by applicable laws, Analytics Intelligence
          shall not be liable for the Comments or any liability, damages, or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
        </li>
      </ul>
      <p className="mt-5">
        Analytics Intelligence reserves the right to monitor all Comments and to
        remove any Comments which can be considered inappropriate, offensive, or
        causes breach of these Terms and Conditions.
      </p>
      <h2 className="mt-4 mb-4" style={{ marginTop: ".8rem" }}>
        You warrant and represent that:
      </h2>
      <ul>
        <li>
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </li>
        <li>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent, or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libellous, offensive,
          indecent, or otherwise unlawful material which is an invasion of
          privacy;
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </li>
        <li>
          You hereby grant Analytics Intelligence a non-exclusive license to
          use, reproduce, edit and authorize others to use, reproduce and edit
          any of your comments in any form, format, or media. You warrant and
          represent that:
        </li>
      </ul>
      <h2 className="mt-4 mb-4" style={{ marginTop: ".8rem" }}>
        Hyperlinking to our Content
      </h2>
      <p className="mt-5">
        The following organizations may link to our Website without prior
        written approval:
      </p>
      <ul>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organizations;</li>
        <li>
          Online directory distributors may link to our Website in the same
          manner as they hyperlink to the Websites of other listed businesses;
          and
        </li>
        <li>
          System-wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups.
        </li>
      </ul>
      <p className="mt-5">
        These organizations may link to our home page, to publications, or other
        Website information so long as the link: (a) is not in any way
        deceptive; (b) does not falsely imply sponsorship, endorsement, or
        approval of the linking party and its products and/or services; and (c)
        fits within the context of the linking party's site.
      </p>
      <h2 className="mt-4 mb-4" style={{ marginTop: ".8rem" }}>
        We may consider and approve other link requests from the following types
        of organizations:
      </h2>
      <ul>
        <li>Commonly-known consumer and/or business information sources;</li>
        <li>Dot.com community sites;</li>
        <li>Associations or other groups representing charities;</li>
        <li>Online directory distributors;</li>
        <li>Internet portals;</li>
        <li>Accounting, law, and consulting firms; and</li>
        <li>Educational institutions and trade associations.</li>
      </ul>
      <p className="mt-5">
        We will approve link requests from these organizations if we decide
        that: (a) the link would not make us look unfavourably to ourselves or
        our accredited businesses; (b) the organization does not have any
        negative records with us; (c) the benefit to us from the visibility of
        the hyperlink compensates the absence of Analytics Intelligence or
        Analytics Intelligence Sites (d) the link is in the context of general
        resource information. <br />
        These organizations may link to our home page so long as the link: (a)
        is not in any way deceptive; (b) does not falsely imply sponsorship,
        endorsement, or approval of the linking party and its products or
        services; and (c) fits within the context of the linking party's site.{" "}
        <br />
        If you are one of the organizations listed in paragraph 2 above and are
        interested in linking to our website, you must inform us by sending an
        e-mail to info@analyticsintelligence.com. Please include your name, your
        organization name, contact information as well as the URL of your site,
        a list of any URLs from which you intend to link to our Website, and a
        list of the URLs on our site to which you would like to link. Wait 2-3
        weeks for a response. <br />
      </p>
      <h2 className="mt-5">
        Approved organizations may hyperlink to our Website as follows:
      </h2>
      <h2 className="mt-5">iFrames</h2>
      Without prior approval and written permission, you may not create frames
      around our Webpages that alter in any way the visual presentation or
      appearance of our Website.
      <h2 className="mt-5">Content Liability</h2>
      We shall not be held responsible for any content that appears on your
      Website. You agree to indemnify us against all claims arising from your
      Website.
      <h2 className="mt-5">
        User Information/Know-Your-Customer (KYC) Requirements
      </h2>
      In creating an Account and registering, or accessing our services, we may
      require you to provide and/or confirm information and documentation that
      will allow us to identify you, such as:
      <li>
        A copy of your government-issued photo ID, such as a passport, driver's
        license, or other valid means of identification;
      </li>
      <li>
        Copies of Corporate Affairs Commission certificate and license to
        related affiliate bodies Such other information and documentation that
        we may require from time to time.
      </li>
      <li>
        You hereby authorize us to, directly or through a third party, obtain,
        verify, and record information and documentation that helps us verify
        your identity and other relevant information.
      </li>
      <h2 className="mt-5">Reservation of Rights</h2>
      We reserve the right to request that you remove all links (including APIs,
      keys, etc) or any particular link to our Website. You approve to
      immediately remove all links to our Website upon request. We also reserve
      the right to amend these terms and conditions and its linking policy at
      any time. By continuously linking to our Website, you agree to be bound to
      and follow these linking terms and conditions.
      <h2 className="mt-5">Disclaimer</h2>
      To the maximum extent permitted by applicable law, we exclude all
      representations, warranties, and conditions relating to our website and
      the use of this website. Nothing in this disclaimer will: limit or exclude
      our or your liability for death or personal injury; limit any of our or
      your liabilities in any way that is not permitted under applicable law; or
      exclude any of our or your liabilities that may not be excluded under
      applicable law. The limitations and prohibitions of liability set in this
      Section and elsewhere in this disclaimer: (a) are subject to the preceding
      paragraph; and (b) govern all liabilities arising under the disclaimer,
      including liabilities arising in contract, in tort, and for breach of
      statutory duty. As long as the website and the information and services on
      the website are provided free of charge, we will not be liable for any
      loss or damage of any nature.
      <h2>Review of Terms of Use</h2>
      We may, at our sole discretion, review, revise and/or update this Terms of
      Use on the Platform at any time. We, therefore, advise that you read
      through these Terms of Use periodically. You further agree that we will
      have no further obligation to notify you of any modifications and such
      updates or modifications shall become effective immediately upon the
      posting thereof or on any specified date. The most current version of the
      Terms of Use can be accessed at any time by selecting the Terms of Use
      link at the bottom of the home page for the platform.
      <style jsx="true">
        {`
          .mt-5 {
            margin-top: 0.9rem;
            margin-bottom: 0.9rem;
          }
          .bold {
            font-weight: bold;
          }
        `}
      </style>
    </div>
  );
}
